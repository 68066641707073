import { createSlice } from '@reduxjs/toolkit';

// utils
import contentfulClient from '../contentful';
import api from "../api";
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  error: null,
  posts: [],
  post: null,
  categories: [],
  recent: [],
};

const slice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.loading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.loading = false;
      state.posts = action.payload;
    },

    // GET CATEGORIES
    getCategoriesSuccess(state, action) {
      state.loading = false;
      state.categories = action.payload;
    },

    // GET RECENT POSTS
    getRecentPostsSuccess(state, action) {
      state.loading = false;
      state.recent = action.payload;
    },

    // GET POST
    getPostSuccess(state, action) {
      state.loading = false;
      state.post = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getRecentPosts(payload) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const contentfulPosts = await contentfulClient.getEntries({ content_type: 'posts', limit: 5, include: 4 });
      const { data } = await api.get('/posts', payload);
      const mapped = contentfulPosts.items.map(i => i.fields);
      const combined = [...data.results, ...mapped];
      dispatch(slice.actions.getRecentPostsSuccess(combined));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPosts(query = {}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const contentfulPosts = await contentfulClient.getEntries({
        content_type: 'posts',
        include: 4,
        ...query,
      });
      const { data } = await api.get('/posts');
      const mapped = contentfulPosts.items.map(i => i.fields);
      const combined = [...data.results, ...mapped];
      dispatch(slice.actions.getPostsSuccess(combined));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCategories(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { items } = await contentfulClient.getEntries({ content_type: 'postsCategories' });
      dispatch(slice.actions.getCategoriesSuccess(items));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPost(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const found = params.slug.match(/\brecs-report\b/g);
      let contentfulPost = [];
      if (!found || found.length === 0) {
        contentfulPost = await contentfulClient.getEntries(params.query);
        const mapped = contentfulPost.items ? contentfulPost.items.map(i => i.fields) : [];
        dispatch(slice.actions.getPostSuccess(mapped[0]));
        return;
      } 
  
      const { data } = await api.get('/posts/fetch', {
        slug: params.slug,
      });
      dispatch(slice.actions.getPostSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}