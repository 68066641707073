import { useEffect, useRef } from 'react';
import generator from 'generate-password';
import _ from 'lodash';

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const isNative = () => {
  const isNative = localStorage.getItem('isNative');
  return isNative === true || isNative === "true";
}

export const isOnboarded = () => {
  const isOnboarded = localStorage.getItem('isOnboarded');
  return isOnboarded === true || isOnboarded === "true";
}

export const getUserRoles = (user) => {
  if (!user  || !user.roles) return [];
  return user.roles.map((r) => r.name);
}

export const checkAuth = (signedIn, roles, role) => {
  if (role && roles && !roles.includes(role)) return false;
  return signedIn;
}

export const generatePassword = () => generator.generate({
  length: 10,
  numbers: true
});

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const addSpaces = (string) => {
  return string.split(' ').slice(0, -1).join(' ');
}

export const isEqual = (a, b) => {
  return _.reduce(a, function(result, value, key) {
      return _.isEqual(value, b[key]) ?
          result : result.concat(key);
  }, []);
}

export default {
  usePrevious,
  isNative,
  isOnboarded,
  getUserRoles,
  checkAuth,
  generatePassword,
  capitalize,
  addSpaces,
  isEqual,
}