import { createSlice } from '@reduxjs/toolkit';

import { NotificationManager } from "../../components/common/react-notifications";

// utils
import api from "../api";
//
import { dispatch } from '../store';

import {
  register,
  emailExists,
  loginUser,
} from '../../services/auth';

import generator from 'generate-password';

import { setUserProfile } from './user';

import _ from 'lodash';

// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  error: null,
  application: null,
  pdf: null,
  type: 'rent',
  draft: null,
  applications: [],
  password_confirmation: false,
};

const slice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.loading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    // GET BUDGETS
    getApplicationsSuccess(state, action) {
      state.loading = false;
      state.applications = action.payload;
    },

    // GET APPLICATION
    getApplicationSuccess(state, action) {
      state.loading = false;
      state.application = action.payload;
    },

    // CREATE APPLICATION
    createApplicationSuccess(state, action) {
      state.loading = false;
      state.draft = null;
      const { application: newApplication } = action.payload.response;
      if (state.applications && state.applications.results) state.applications.results = [...state.applications.results, newApplication];
    },

    // UPDATE APPLICATION
    updateApplicationSuccess(state, action) {
      state.loading = false;
      const newApplication = action.payload.response;
      const index = state.applications.results.findIndex(x => x._id == newApplication._id);
      state.applications.results[index] = newApplication;
    },

    // DELETE APPLICATION
    deleteApplicationSuccess(state, action) {
      state.loading = false;
      const deletedId = action.payload;
      const newApplications = state.applications.results.filter(i => i._id !== deletedId);
      state.applications.results = [...newApplications];
    },

    // STORE DRAFT
    applicationStoreDraft(state, action) {
      state.draft = action.payload.data;
    },

    setApplicationPasswordConfirm(state, action) {
      state.password_confirmation = action.payload;
    },  

    getApplicationPdfSuccess(state, action) {
      state.loading = false;
      const file = new Blob(
        [action.payload], 
        {type: 'application/pdf'}
      );
      state.pdf = file;
    },

    setApplicationType(state, action) {
      state.type = action.payload;
    },

    setApplicationPdf(state, action) {
      state.pdf = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

const uploadFiles= async (files) => {
  const promises = files.map((file, k) => {
    if (file.s3_key || (file.file && file.file.s3_key)) { 
      return {
        data: file
      };
    } else if (file.file) {
      return api.upload('/applications/file', file.file, true);
    }
    return {};
  })
  return Promise.all(promises);
};

const uploadFile = async (payload) => {
  return api.upload('/applications/file', payload, true);
};

const addFiles = async (payload) => {
  let { 
    nht, 
    ability_to_pay, 
    mortgage, 
    identifications, 
    utilities,
    pay_stubs,
  } = payload;

  let application = _.cloneDeep(payload);

  return new Promise(async (resolve, reject ) => {
    try {
      if (nht && Object.keys(nht).length > 0 && !nht.s3_key) {
        const nhtFile = await uploadFile(payload.nht);
        application.nht = nhtFile.data.response
      } 

      if (ability_to_pay && Object.keys(ability_to_pay).length > 0 && !ability_to_pay.s3_key) {
        const AbilityToPayFile = await uploadFile(payload.ability_to_pay);
        application.ability_to_pay = AbilityToPayFile.data.response
      }

      if (mortgage && Object.keys(mortgage).length > 0 && !mortgage.s3_key) {
        const mortgageFile = await uploadFile(payload.mortgage);
        application.mortgage = mortgageFile.data.response
      } 

      if (identifications && !identifications.s3_key) {
        const identificationFile = await uploadFile(payload.identifications);
        application.identifications = identificationFile.data.response;
      } 

      if (utilities && utilities.find(e => !e.s3_key)) {
        const files = await uploadFiles(utilities);
        files.map((file, k) => {
          if (!file.data.file ) {
            application.utilities[k].file = file.data.response;
          }
        })
      } 

      if (pay_stubs && pay_stubs.find(e => !e.s3_key)) {
        const files = await uploadFiles(pay_stubs);
        files.map((file, k) => {
          application.pay_stubs[k].file = file.data.response;
        })
      } 

      resolve(application);
    } catch (error) {
      reject(error);
    }
  });
}

// ----------------------------------------------------------------------

export function createApplication(payload) {
  return async () => {
    // store a version as a draft
    dispatch(slice.actions.startLoading());

    dispatch(slice.actions.applicationStoreDraft(payload));

    try {
    const { user: existingUser, data: allData } = payload;
    // check if user is logged in 
    if (!existingUser || Object.keys(existingUser).length === 0) {
      const exists = await emailExists({  email: allData.email });
      if (exists && exists.data && exists.data.email) {
        dispatch(slice.actions.setApplicationPasswordConfirm(true));
        return;
      }
    
      const password = generator.generate({
        length: 10,
        numbers: true
      });

      const user = await register({
        user: { 
          first_name: allData.first_name,
          last_name: allData.last_name,
          email: allData.email, 
          registerType: 'guest', 
          password: password 
        },
        history: null,
        skipToggle: true
      });

      const uuid = await loginUser({
        user: {
          email: allData.email,
          password: password,
          LoginType: 'login',
        },
        skipToggle: true,
      });
    }

      const dataWithFiles = await addFiles(allData);
      const { data } = await api.post('/applications', dataWithFiles, true);
      dispatch(slice.actions.createApplicationSuccess(data));
      NotificationManager.success(
        "Application created",
        "Application was successfully created",
        3000,
        null,
        null,
        ''
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getApplications(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.get('/applications', params, true);
      dispatch(slice.actions.getApplicationsSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateApplication(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.put('/applications', params.id, params.updates, true);
      dispatch(slice.actions.updateApplicationSuccess(data));
      NotificationManager.success(
        "Application updated",
        "Application was successfully updated",
        3000,
        null,
        null,
        ''
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getApplication(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.getById('/applications', params, true);
      dispatch(slice.actions.getApplicationSuccess(data.response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteApplication(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.delete('/applications', params, true);
      dispatch(slice.actions.deleteApplicationSuccess(params));
      NotificationManager.success(
        "Application deleted",
        "Application was successfully deleted",
        3000,
        null,
        null,
        ''
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getApplicationAsPdf(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.get(`/applications/${id}/pdf`, null, true, 'blob');
      dispatch(slice.actions.getApplicationPdfSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setApplicationType(type) {
  return async () => {
    dispatch(slice.actions.setApplicationType(type));
  }
}

export function setApplicationPdf(pdf = null) {
  return async () => {
    dispatch(slice.actions.setApplicationPdf(pdf));
  }
}