import { createSlice } from '@reduxjs/toolkit';

// utils
import contentfulClient from '../contentful'
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
	footer: null,
	regions: null,
	region: null,
	error: null,
	loading: false,
	propertyTypes: null,
	propertyFeatures: null,
	navigation: null,
  faqs: null,
};

const slice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.loading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    // GET FOOTER
    getFooterContentSuccess(state, action) {
      state.loading = false;
      state.footer = action.payload;
    },

    // GET REGIONS
    getRegionsContentSuccess(state, action) {
      state.loading = false;
      state.regions = action.payload;
    },

    // GET PROPERTY TYPES
    getPropertyTypesSuccess(state, action) {
      state.loading = false;
      state.propertyTypes = action.payload;
    },

    // GET FEATURES
    getPropertyFeaturesSuccess(state, action) {
      state.loading = false;
      state.propertyFeatures = action.payload;
    },

    // GET NAV
    getNavContentSuccess(state, action) {
      state.loading = false;
      state.navigation = action.payload;
    },

    // GET FAQS
    getFaqsSuccess(state, action) {
      state.loading = false;
      state.faqs = action.payload;
    }
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getFooterContent() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const res = await contentfulClient.getEntries({ content_type: 'footer' });
      const res = {
        "sys": {
            "type": "Array"
        },
        "total": 3,
        "skip": 0,
        "limit": 100,
        "items": [
            {
                "metadata": {
                    "tags": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "61geg9wy6l6n"
                        }
                    },
                    "id": "19A8zU5sUz9z0Ieb8Jmhax",
                    "type": "Entry",
                    "createdAt": "2020-03-30T01:12:36.933Z",
                    "updatedAt": "2020-07-29T17:47:07.886Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "revision": 3,
                    "contentType": {
                        "sys": {
                            "type": "Link",
                            "linkType": "ContentType",
                            "id": "footer"
                        }
                    },
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Home Locations",
                    "links": [
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "sAvp5s0vU7JB0OJPR416x",
                                "type": "Entry",
                                "createdAt": "2020-03-30T01:16:53.864Z",
                                "updatedAt": "2020-05-02T02:27:07.049Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 3,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "footerLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "title": "Homes for sale in Negril",
                                "alt": "Homes for sale in Negril",
                                "url": "/homes/for-sale/jamaica/westmoreland/negril"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "1QI1LN5bGVWYFOkLsUIzP8",
                                "type": "Entry",
                                "createdAt": "2020-03-30T01:15:55.243Z",
                                "updatedAt": "2020-05-02T02:25:44.402Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 3,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "footerLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "title": "Homes for sale in Kingston",
                                "alt": "Homes for sale in Kingston",
                                "url": "/homes/for-sale/jamaica/kingston"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "2oa5InRcm1mUD3fvROENJW",
                                "type": "Entry",
                                "createdAt": "2020-03-30T01:16:36.836Z",
                                "updatedAt": "2020-05-02T02:26:09.745Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 3,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "footerLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "title": "Homes for sale in Montego Bay",
                                "alt": "Homes for sale in Montego Bay",
                                "url": "/homes/for-sale/jamaica/saint-james/montego-bay"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "4Vjkh4zkyIhaueF4FajpqZ",
                                "type": "Entry",
                                "createdAt": "2020-03-30T01:16:21.313Z",
                                "updatedAt": "2020-05-02T02:26:24.196Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 3,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "footerLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "title": "Homes for sale in Ocho Rios",
                                "alt": "Homes for sale in Ocho Rios",
                                "url": "/homes/for-sale/jamaica/saint-ann/ocho-rios"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "4tzP47WxC1XXqohNS2xw8f",
                                "type": "Entry",
                                "createdAt": "2020-03-30T01:17:57.303Z",
                                "updatedAt": "2020-05-02T02:27:42.993Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 3,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "footerLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "title": "Homes for rent in Negril",
                                "alt": "Homes for rent in Negril",
                                "url": "/homes/for-rent/jamaica/westmoreland/negril"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "rHBXxgvT9KaSffFx2LKRg",
                                "type": "Entry",
                                "createdAt": "2020-03-30T01:17:08.479Z",
                                "updatedAt": "2020-05-02T02:27:19.347Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 3,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "footerLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "title": "Homes for rent in Kingston",
                                "alt": "Homes for rent in Kingston",
                                "url": "/homes/for-rent/jamaica/kingston"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "7kHnAlul6omzUdplr75I4e",
                                "type": "Entry",
                                "createdAt": "2020-03-30T01:17:40.615Z",
                                "updatedAt": "2020-05-02T02:27:31.740Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 3,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "footerLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "title": "Homes for rent in Montego Bay",
                                "alt": "Homes for rent in Montego Bay",
                                "url": "/homes/for-rent/jamaica/saint-james/montego-bay"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "45OrMkUXdTrUQ9RA1V5SeE",
                                "type": "Entry",
                                "createdAt": "2020-03-30T01:17:25.354Z",
                                "updatedAt": "2020-05-02T02:26:37.348Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 3,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "footerLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "title": "Homes for rent in Ocho Rios",
                                "alt": "Homes for rent in Ocho Rios",
                                "url": "/homes/for-rent/jamaica/saint-ann/ocho-rios"
                            }
                        }
                    ]
                }
            },
            {
                "metadata": {
                    "tags": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "61geg9wy6l6n"
                        }
                    },
                    "id": "4YHN8aZhb7a57wWPDCA706",
                    "type": "Entry",
                    "createdAt": "2020-03-30T01:12:19.552Z",
                    "updatedAt": "2020-07-29T17:46:24.613Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "revision": 3,
                    "contentType": {
                        "sys": {
                            "type": "Link",
                            "linkType": "ContentType",
                            "id": "footer"
                        }
                    },
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Home Types",
                    "links": [
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "64j9CFkpA6P4arfha5psFg",
                                "type": "Entry",
                                "createdAt": "2020-03-30T01:13:32.012Z",
                                "updatedAt": "2020-05-02T02:24:13.278Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 3,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "footerLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "title": "Homes for sale in Jamaica",
                                "alt": "Homes for sale in Jamaica",
                                "url": "/homes/for-sale/jamaica"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "6ox198m1nIoVLSr1YR8Q4D",
                                "type": "Entry",
                                "createdAt": "2020-03-30T01:14:08.682Z",
                                "updatedAt": "2020-07-29T17:50:58.028Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 4,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "footerLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "title": "Apartments for sale in Jamaica",
                                "alt": "Apartments for sale in Jamaica",
                                "url": "/homes/for-sale/jamaica/apartment"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "5gpOAJWkAf0cWbLBVDlvrk",
                                "type": "Entry",
                                "createdAt": "2020-03-30T01:13:51.503Z",
                                "updatedAt": "2020-05-02T02:24:36.285Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 3,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "footerLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "title": "Land for sale in Jamaica",
                                "alt": "Land for sale in Jamaica",
                                "url": "/homes/for-sale/jamaica/land"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "PKas9Cwpr2plJjxieJEte",
                                "type": "Entry",
                                "createdAt": "2020-03-30T01:14:26.307Z",
                                "updatedAt": "2020-08-11T13:04:16.606Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 4,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "footerLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "title": "Beach front homes for sale in Jamaica",
                                "alt": "Beach front homes for sale in Jamaica",
                                "url": "/homes/for-sale/jamaica?features=beach-front"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "22sNyggrhDAXPDbEnb3ncb",
                                "type": "Entry",
                                "createdAt": "2020-03-30T01:14:40.356Z",
                                "updatedAt": "2020-05-02T02:24:24.561Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 3,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "footerLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "title": "Homes for rent in Jamaica",
                                "alt": "Homes for rent in Jamaica",
                                "url": "/homes/for-rent/jamaica"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "55OKSHYnWgQ0farNPrghHk",
                                "type": "Entry",
                                "createdAt": "2020-03-30T01:15:10.683Z",
                                "updatedAt": "2022-10-30T13:57:03.674Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 4,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "footerLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "title": "Apartments for rent in Jamaica",
                                "alt": "Apartments for rent in Jamaica",
                                "url": "/homes/for-rent/jamaica/apartment"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "7MKG9PUZFaWLGwiNO8or6o",
                                "type": "Entry",
                                "createdAt": "2020-03-30T01:14:54.666Z",
                                "updatedAt": "2020-05-02T02:24:47.579Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 3,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "footerLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "title": "Land for rent in Jamaica",
                                "alt": "Land for rent in Jamaica",
                                "url": "/homes/for-rent/jamaica/land"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "2w7WNLmutXRxV3KXur47PC",
                                "type": "Entry",
                                "createdAt": "2020-03-30T01:15:26.934Z",
                                "updatedAt": "2020-08-11T13:03:54.650Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 5,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "footerLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "title": "Beach front homes for rent in Jamaica",
                                "alt": "Beach front homes for rent in Jamaica",
                                "url": "/homes/for-rent/jamaica?features=beach-front"
                            }
                        }
                    ]
                }
            },
            {
                "metadata": {
                    "tags": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "61geg9wy6l6n"
                        }
                    },
                    "id": "C895jKmY73XnFWWlcl9np",
                    "type": "Entry",
                    "createdAt": "2020-03-30T01:12:59.820Z",
                    "updatedAt": "2020-07-29T17:44:46.766Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "revision": 3,
                    "contentType": {
                        "sys": {
                            "type": "Link",
                            "linkType": "ContentType",
                            "id": "footer"
                        }
                    },
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Additional Links",
                    "links": [
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "5oEqgTP5ZAT4yUNGztd3gQ",
                                "type": "Entry",
                                "createdAt": "2020-03-30T01:18:14.500Z",
                                "updatedAt": "2020-07-29T17:41:38.636Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 2,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "footerLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "title": "Real Estate Guide",
                                "alt": "Real Estate Guide",
                                "url": "/real-estate/guide/all"
                            }
                        }
                    ]
                }
            }
        ],
        "includes": {
            "Entry": [
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "1QI1LN5bGVWYFOkLsUIzP8",
                        "type": "Entry",
                        "createdAt": "2020-03-30T01:15:55.243Z",
                        "updatedAt": "2020-05-02T02:25:44.402Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 3,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "footerLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Homes for sale in Kingston",
                        "alt": "Homes for sale in Kingston",
                        "url": "/homes/for-sale/jamaica/kingston"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "22sNyggrhDAXPDbEnb3ncb",
                        "type": "Entry",
                        "createdAt": "2020-03-30T01:14:40.356Z",
                        "updatedAt": "2020-05-02T02:24:24.561Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 3,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "footerLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Homes for rent in Jamaica",
                        "alt": "Homes for rent in Jamaica",
                        "url": "/homes/for-rent/jamaica"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "2oa5InRcm1mUD3fvROENJW",
                        "type": "Entry",
                        "createdAt": "2020-03-30T01:16:36.836Z",
                        "updatedAt": "2020-05-02T02:26:09.745Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 3,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "footerLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Homes for sale in Montego Bay",
                        "alt": "Homes for sale in Montego Bay",
                        "url": "/homes/for-sale/jamaica/saint-james/montego-bay"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "2w7WNLmutXRxV3KXur47PC",
                        "type": "Entry",
                        "createdAt": "2020-03-30T01:15:26.934Z",
                        "updatedAt": "2020-08-11T13:03:54.650Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 5,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "footerLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Beach front homes for rent in Jamaica",
                        "alt": "Beach front homes for rent in Jamaica",
                        "url": "/homes/for-rent/jamaica?features=beach-front"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "45OrMkUXdTrUQ9RA1V5SeE",
                        "type": "Entry",
                        "createdAt": "2020-03-30T01:17:25.354Z",
                        "updatedAt": "2020-05-02T02:26:37.348Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 3,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "footerLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Homes for rent in Ocho Rios",
                        "alt": "Homes for rent in Ocho Rios",
                        "url": "/homes/for-rent/jamaica/saint-ann/ocho-rios"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "4Vjkh4zkyIhaueF4FajpqZ",
                        "type": "Entry",
                        "createdAt": "2020-03-30T01:16:21.313Z",
                        "updatedAt": "2020-05-02T02:26:24.196Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 3,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "footerLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Homes for sale in Ocho Rios",
                        "alt": "Homes for sale in Ocho Rios",
                        "url": "/homes/for-sale/jamaica/saint-ann/ocho-rios"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "4tzP47WxC1XXqohNS2xw8f",
                        "type": "Entry",
                        "createdAt": "2020-03-30T01:17:57.303Z",
                        "updatedAt": "2020-05-02T02:27:42.993Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 3,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "footerLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Homes for rent in Negril",
                        "alt": "Homes for rent in Negril",
                        "url": "/homes/for-rent/jamaica/westmoreland/negril"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "55OKSHYnWgQ0farNPrghHk",
                        "type": "Entry",
                        "createdAt": "2020-03-30T01:15:10.683Z",
                        "updatedAt": "2022-10-30T13:57:03.674Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 4,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "footerLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Apartments for rent in Jamaica",
                        "alt": "Apartments for rent in Jamaica",
                        "url": "/homes/for-rent/jamaica/apartment"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "5gpOAJWkAf0cWbLBVDlvrk",
                        "type": "Entry",
                        "createdAt": "2020-03-30T01:13:51.503Z",
                        "updatedAt": "2020-05-02T02:24:36.285Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 3,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "footerLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Land for sale in Jamaica",
                        "alt": "Land for sale in Jamaica",
                        "url": "/homes/for-sale/jamaica/land"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "5oEqgTP5ZAT4yUNGztd3gQ",
                        "type": "Entry",
                        "createdAt": "2020-03-30T01:18:14.500Z",
                        "updatedAt": "2020-07-29T17:41:38.636Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 2,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "footerLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Real Estate Guide",
                        "alt": "Real Estate Guide",
                        "url": "/real-estate/guide/all"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "64j9CFkpA6P4arfha5psFg",
                        "type": "Entry",
                        "createdAt": "2020-03-30T01:13:32.012Z",
                        "updatedAt": "2020-05-02T02:24:13.278Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 3,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "footerLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Homes for sale in Jamaica",
                        "alt": "Homes for sale in Jamaica",
                        "url": "/homes/for-sale/jamaica"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "6ox198m1nIoVLSr1YR8Q4D",
                        "type": "Entry",
                        "createdAt": "2020-03-30T01:14:08.682Z",
                        "updatedAt": "2020-07-29T17:50:58.028Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 4,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "footerLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Apartments for sale in Jamaica",
                        "alt": "Apartments for sale in Jamaica",
                        "url": "/homes/for-sale/jamaica/apartment"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "7MKG9PUZFaWLGwiNO8or6o",
                        "type": "Entry",
                        "createdAt": "2020-03-30T01:14:54.666Z",
                        "updatedAt": "2020-05-02T02:24:47.579Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 3,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "footerLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Land for rent in Jamaica",
                        "alt": "Land for rent in Jamaica",
                        "url": "/homes/for-rent/jamaica/land"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "7kHnAlul6omzUdplr75I4e",
                        "type": "Entry",
                        "createdAt": "2020-03-30T01:17:40.615Z",
                        "updatedAt": "2020-05-02T02:27:31.740Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 3,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "footerLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Homes for rent in Montego Bay",
                        "alt": "Homes for rent in Montego Bay",
                        "url": "/homes/for-rent/jamaica/saint-james/montego-bay"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "PKas9Cwpr2plJjxieJEte",
                        "type": "Entry",
                        "createdAt": "2020-03-30T01:14:26.307Z",
                        "updatedAt": "2020-08-11T13:04:16.606Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 4,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "footerLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Beach front homes for sale in Jamaica",
                        "alt": "Beach front homes for sale in Jamaica",
                        "url": "/homes/for-sale/jamaica?features=beach-front"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "rHBXxgvT9KaSffFx2LKRg",
                        "type": "Entry",
                        "createdAt": "2020-03-30T01:17:08.479Z",
                        "updatedAt": "2020-05-02T02:27:19.347Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 3,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "footerLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Homes for rent in Kingston",
                        "alt": "Homes for rent in Kingston",
                        "url": "/homes/for-rent/jamaica/kingston"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "sAvp5s0vU7JB0OJPR416x",
                        "type": "Entry",
                        "createdAt": "2020-03-30T01:16:53.864Z",
                        "updatedAt": "2020-05-02T02:27:07.049Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 3,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "footerLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Homes for sale in Negril",
                        "alt": "Homes for sale in Negril",
                        "url": "/homes/for-sale/jamaica/westmoreland/negril"
                    }
                }
            ]
        }
      };
      dispatch(slice.actions.getFooterContentSuccess(res));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getRegionsContent() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const res = await contentfulClient.getEntries({ content_type: 'regions' });
      const res = {
        "sys": {
            "type": "Array"
        },
        "total": 14,
        "skip": 0,
        "limit": 100,
        "items": [
            {
                "metadata": {
                    "tags": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "61geg9wy6l6n"
                        }
                    },
                    "id": "2CpJrd1x7dgPg1AcEGzhXl",
                    "type": "Entry",
                    "createdAt": "2020-03-30T14:30:43.643Z",
                    "updatedAt": "2021-04-10T04:51:18.280Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "revision": 8,
                    "contentType": {
                        "sys": {
                            "type": "Link",
                            "linkType": "ContentType",
                            "id": "regions"
                        }
                    },
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Clarendon",
                    "tagLine": "Agriculture",
                    "description": "Clarendon is best known for being the centre of the agricultural industry in Jamaica. Denbigh, a small town in Clarendon, has hosted the largest agriculture show in the Caribbean annually since Jamaica’s Independence, celebrating Jamaica’s wealth of domestic and agricultural products. Its capital, MayPen is a major market centre for the parish, which is located along the Rio Minho River, which is the longest river on the island.",
                    "image": {
                        "metadata": {
                            "tags": []
                        },
                        "sys": {
                            "space": {
                                "sys": {
                                    "type": "Link",
                                    "linkType": "Space",
                                    "id": "61geg9wy6l6n"
                                }
                            },
                            "id": "3SMrPYYVyC4tw1Q3DGNKXF",
                            "type": "Asset",
                            "createdAt": "2020-03-30T14:38:15.912Z",
                            "updatedAt": "2020-07-16T15:10:39.209Z",
                            "environment": {
                                "sys": {
                                    "id": "master",
                                    "type": "Link",
                                    "linkType": "Environment"
                                }
                            },
                            "revision": 3,
                            "locale": "en-US"
                        },
                        "fields": {
                            "title": "clarendon",
                            "file": {
                                "url": "//images.ctfassets.net/61geg9wy6l6n/3SMrPYYVyC4tw1Q3DGNKXF/3034b765f8a7fd4abfea8cb6ac128626/clarendon.jpg",
                                "details": {
                                    "size": 65232,
                                    "image": {
                                        "width": 800,
                                        "height": 534
                                    }
                                },
                                "fileName": "clarendon.jpg",
                                "contentType": "image/jpeg"
                            }
                        }
                    },
                    "featured": true,
                    "url": "/homes/for-sale/jamaica/claredon",
                    "slug": "Claredon"
                }
            },
            {
                "metadata": {
                    "tags": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "61geg9wy6l6n"
                        }
                    },
                    "id": "6MhCEs1pvd2uR8CnGr5CWW",
                    "type": "Entry",
                    "createdAt": "2020-03-30T14:29:55.743Z",
                    "updatedAt": "2020-09-14T02:47:41.845Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "revision": 7,
                    "contentType": {
                        "sys": {
                            "type": "Link",
                            "linkType": "ContentType",
                            "id": "regions"
                        }
                    },
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Manchester",
                    "tagLine": "Mountainous",
                    "description": "On the south-central side of the island lies Manchester, one of the most mountainous parishes. Its capital, Mandeville has often been described as the “typical English village” since British expats often reside there due to its cool climate year round. Today, Mandeville is one of the largest and most affluent urban areas in Jamaica.",
                    "image": {
                        "metadata": {
                            "tags": []
                        },
                        "sys": {
                            "space": {
                                "sys": {
                                    "type": "Link",
                                    "linkType": "Space",
                                    "id": "61geg9wy6l6n"
                                }
                            },
                            "id": "emjuPr7paOkH8FV8nqF2T",
                            "type": "Asset",
                            "createdAt": "2020-03-30T14:39:28.140Z",
                            "updatedAt": "2020-03-30T14:39:28.140Z",
                            "environment": {
                                "sys": {
                                    "id": "master",
                                    "type": "Link",
                                    "linkType": "Environment"
                                }
                            },
                            "revision": 1,
                            "locale": "en-US"
                        },
                        "fields": {
                            "title": "manchester",
                            "file": {
                                "url": "//images.ctfassets.net/61geg9wy6l6n/emjuPr7paOkH8FV8nqF2T/817bfa60e2c39339d4722aa174cc582b/manchester.jpg",
                                "details": {
                                    "size": 127780,
                                    "image": {
                                        "width": 800,
                                        "height": 533
                                    }
                                },
                                "fileName": "manchester.jpg",
                                "contentType": "image/jpeg"
                            }
                        }
                    },
                    "featured": true,
                    "url": "/homes/for-sale/jamaica/manchester",
                    "slug": "Manchester"
                }
            },
            {
                "metadata": {
                    "tags": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "61geg9wy6l6n"
                        }
                    },
                    "id": "26XVvzzjPoE4xz8Rn1Zk4L",
                    "type": "Entry",
                    "createdAt": "2020-03-30T14:31:05.707Z",
                    "updatedAt": "2020-09-14T02:47:30.456Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "revision": 7,
                    "contentType": {
                        "sys": {
                            "type": "Link",
                            "linkType": "ContentType",
                            "id": "regions"
                        }
                    },
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Portland",
                    "tagLine": "Lush Landscapes",
                    "description": "Truly Jamaica’s best kept secret, Portland is a sight to behold of untouched lush landscape. Since it rains all year round, Portland offers a celestial freshness that instantly captivates everyone. Beautiful beaches, caves, lagoons, mountains, and waterfalls are some of the parish’s most prized features. It’s also the birthplace of jerk chicken!",
                    "image": {
                        "metadata": {
                            "tags": []
                        },
                        "sys": {
                            "space": {
                                "sys": {
                                    "type": "Link",
                                    "linkType": "Space",
                                    "id": "61geg9wy6l6n"
                                }
                            },
                            "id": "3cQNxqZAI4Rr0wk4EHpRA7",
                            "type": "Asset",
                            "createdAt": "2020-03-30T14:40:02.199Z",
                            "updatedAt": "2020-03-30T14:40:02.199Z",
                            "environment": {
                                "sys": {
                                    "id": "master",
                                    "type": "Link",
                                    "linkType": "Environment"
                                }
                            },
                            "revision": 1,
                            "locale": "en-US"
                        },
                        "fields": {
                            "title": "portland",
                            "file": {
                                "url": "//images.ctfassets.net/61geg9wy6l6n/3cQNxqZAI4Rr0wk4EHpRA7/e4708de9b9745becd478182ab578d07d/portland.jpg",
                                "details": {
                                    "size": 160851,
                                    "image": {
                                        "width": 800,
                                        "height": 600
                                    }
                                },
                                "fileName": "portland.jpg",
                                "contentType": "image/jpeg"
                            }
                        }
                    },
                    "featured": true,
                    "url": "/homes/for-sale/jamaica/portland",
                    "slug": "Portland"
                }
            },
            {
                "metadata": {
                    "tags": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "61geg9wy6l6n"
                        }
                    },
                    "id": "2IhBq54k5RoJdcGOqaN5WO",
                    "type": "Entry",
                    "createdAt": "2020-03-30T14:28:28.760Z",
                    "updatedAt": "2020-09-14T02:47:14.567Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "revision": 5,
                    "contentType": {
                        "sys": {
                            "type": "Link",
                            "linkType": "ContentType",
                            "id": "regions"
                        }
                    },
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Saint Mary",
                    "description": "Once a thriving banana region, St. Mary is renowned as one of the most important farming areas on the island. In spite of the decline in its agriculture activities, the parish has seen substantial growth in its tourism. Today St. Mary boasts some of the best beaches, hotel and guesthouses as well as plantation properties that the island has to offer.",
                    "image": {
                        "metadata": {
                            "tags": []
                        },
                        "sys": {
                            "space": {
                                "sys": {
                                    "type": "Link",
                                    "linkType": "Space",
                                    "id": "61geg9wy6l6n"
                                }
                            },
                            "id": "4871MbEQMt4m1OJC1Gy8RG",
                            "type": "Asset",
                            "createdAt": "2020-03-30T14:40:21.827Z",
                            "updatedAt": "2020-03-30T14:40:21.827Z",
                            "environment": {
                                "sys": {
                                    "id": "master",
                                    "type": "Link",
                                    "linkType": "Environment"
                                }
                            },
                            "revision": 1,
                            "locale": "en-US"
                        },
                        "fields": {
                            "title": "saint-mary",
                            "file": {
                                "url": "//images.ctfassets.net/61geg9wy6l6n/4871MbEQMt4m1OJC1Gy8RG/2276c0a96c651d492be995902b525029/saint-mary.jpg",
                                "details": {
                                    "size": 77947,
                                    "image": {
                                        "width": 800,
                                        "height": 450
                                    }
                                },
                                "fileName": "saint-mary.jpg",
                                "contentType": "image/jpeg"
                            }
                        }
                    },
                    "url": "/homes/for-sale/jamaica/saint-mary",
                    "slug": "St. Mary"
                }
            },
            {
                "metadata": {
                    "tags": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "61geg9wy6l6n"
                        }
                    },
                    "id": "1rwUhW8fmZv6QtvQC21yqQ",
                    "type": "Entry",
                    "createdAt": "2020-03-30T14:28:02.961Z",
                    "updatedAt": "2020-09-14T02:47:02.255Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "revision": 5,
                    "contentType": {
                        "sys": {
                            "type": "Link",
                            "linkType": "ContentType",
                            "id": "regions"
                        }
                    },
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Trelawny",
                    "description": "This port district welcomes cruise ships from around the world on a daily basis. Its capital town Falmouth is often treated as an extension of Montego Bay due to its important resorts and other tourist attractions. Trelawny is also revered for it mountainous landscape.",
                    "image": {
                        "metadata": {
                            "tags": []
                        },
                        "sys": {
                            "space": {
                                "sys": {
                                    "type": "Link",
                                    "linkType": "Space",
                                    "id": "61geg9wy6l6n"
                                }
                            },
                            "id": "6FJcMPnbYOYFiD7OoXwnk2",
                            "type": "Asset",
                            "createdAt": "2020-03-30T14:40:46.644Z",
                            "updatedAt": "2020-03-30T14:40:46.644Z",
                            "environment": {
                                "sys": {
                                    "id": "master",
                                    "type": "Link",
                                    "linkType": "Environment"
                                }
                            },
                            "revision": 1,
                            "locale": "en-US"
                        },
                        "fields": {
                            "title": "trelawny",
                            "file": {
                                "url": "//images.ctfassets.net/61geg9wy6l6n/6FJcMPnbYOYFiD7OoXwnk2/5b54f9c91e28a6dcfe69df46e12bdaa5/trelawny.jpg",
                                "details": {
                                    "size": 144313,
                                    "image": {
                                        "width": 800,
                                        "height": 600
                                    }
                                },
                                "fileName": "trelawny.jpg",
                                "contentType": "image/jpeg"
                            }
                        }
                    },
                    "url": "/homes/for-sale/jamaica/trelawny",
                    "slug": "Trelawny"
                }
            },
            {
                "metadata": {
                    "tags": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "61geg9wy6l6n"
                        }
                    },
                    "id": "62nRGOVNulHlsl2bRSRIa0",
                    "type": "Entry",
                    "createdAt": "2020-03-30T14:27:30.928Z",
                    "updatedAt": "2020-09-14T02:46:45.807Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "revision": 5,
                    "contentType": {
                        "sys": {
                            "type": "Link",
                            "linkType": "ContentType",
                            "id": "regions"
                        }
                    },
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Saint Elizabeth",
                    "description": "Off the beaten path lies St. Elizabeth, the island’s “bread basket”. This region produces most of the vegetables sold on the island. St. Elizabeth was once the most prosperous commercial centre, with its capital town, Black River, being the first place in Jamaica to install electricity. This parish has a strong heritage; hosts the only crocodile refuge on the island and boast a diverse landscape of swamps, waterfalls, rivers, beaches, mountains, and caves.",
                    "image": {
                        "metadata": {
                            "tags": []
                        },
                        "sys": {
                            "space": {
                                "sys": {
                                    "type": "Link",
                                    "linkType": "Space",
                                    "id": "61geg9wy6l6n"
                                }
                            },
                            "id": "7FV0XQp32ioMO8cUQ3w5nU",
                            "type": "Asset",
                            "createdAt": "2020-03-30T14:41:07.271Z",
                            "updatedAt": "2020-03-30T14:41:07.271Z",
                            "environment": {
                                "sys": {
                                    "id": "master",
                                    "type": "Link",
                                    "linkType": "Environment"
                                }
                            },
                            "revision": 1,
                            "locale": "en-US"
                        },
                        "fields": {
                            "title": "saint-elizabeth",
                            "file": {
                                "url": "//images.ctfassets.net/61geg9wy6l6n/7FV0XQp32ioMO8cUQ3w5nU/4ed69192579a10ab1c532b7d89444b08/saint-elizabeth.jpg",
                                "details": {
                                    "size": 161740,
                                    "image": {
                                        "width": 800,
                                        "height": 600
                                    }
                                },
                                "fileName": "saint-elizabeth.jpg",
                                "contentType": "image/jpeg"
                            }
                        }
                    },
                    "url": "/homes/for-sale/jamaica/saint-elizabeth",
                    "slug": "St. Elizabeth"
                }
            },
            {
                "metadata": {
                    "tags": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "61geg9wy6l6n"
                        }
                    },
                    "id": "5xR42Y28CPNKzsyTs5XJZs",
                    "type": "Entry",
                    "createdAt": "2020-03-30T14:26:56.361Z",
                    "updatedAt": "2020-09-14T02:46:34.183Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "revision": 5,
                    "contentType": {
                        "sys": {
                            "type": "Link",
                            "linkType": "ContentType",
                            "id": "regions"
                        }
                    },
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Saint Catherine",
                    "description": "St. Catherine is one of the island’s largest and most important parishes for commerce. It is the second most populous region and continues to grow rapidly, particularly in a province called Portmore. For locals, St. Catherine’s beaches are a favourite, especially for its seafood fare.",
                    "image": {
                        "metadata": {
                            "tags": []
                        },
                        "sys": {
                            "space": {
                                "sys": {
                                    "type": "Link",
                                    "linkType": "Space",
                                    "id": "61geg9wy6l6n"
                                }
                            },
                            "id": "1FNoHrtEV70e3kGMxlyvbl",
                            "type": "Asset",
                            "createdAt": "2020-03-30T14:41:31.641Z",
                            "updatedAt": "2020-03-30T14:41:31.641Z",
                            "environment": {
                                "sys": {
                                    "id": "master",
                                    "type": "Link",
                                    "linkType": "Environment"
                                }
                            },
                            "revision": 1,
                            "locale": "en-US"
                        },
                        "fields": {
                            "title": "saint-catherine",
                            "file": {
                                "url": "//images.ctfassets.net/61geg9wy6l6n/1FNoHrtEV70e3kGMxlyvbl/33221df40d3343e05fce30ca196114c0/saint-catherine.jpg",
                                "details": {
                                    "size": 150141,
                                    "image": {
                                        "width": 800,
                                        "height": 533
                                    }
                                },
                                "fileName": "saint-catherine.jpg",
                                "contentType": "image/jpeg"
                            }
                        }
                    },
                    "url": "/homes/for-sale/jamaica/saint-catherine",
                    "slug": "St. Catherine"
                }
            },
            {
                "metadata": {
                    "tags": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "61geg9wy6l6n"
                        }
                    },
                    "id": "3YWCxEUTMWBgE1kT7KuFXx",
                    "type": "Entry",
                    "createdAt": "2020-03-30T14:26:15.801Z",
                    "updatedAt": "2020-09-14T02:46:20.094Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "revision": 5,
                    "contentType": {
                        "sys": {
                            "type": "Link",
                            "linkType": "ContentType",
                            "id": "regions"
                        }
                    },
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Saint Ann",
                    "description": "The “Garden Parish” of St. Ann is one of the most popular regions on the island, especially for tourists. Home to one of the most famous waterfalls in the Caribbean, Dunn’s River Falls, it boasts a stunning coastline with turquoise seas and hidden gems like its over 60 underground caves. Its most famous town is called Ocho Rios.",
                    "image": {
                        "metadata": {
                            "tags": []
                        },
                        "sys": {
                            "space": {
                                "sys": {
                                    "type": "Link",
                                    "linkType": "Space",
                                    "id": "61geg9wy6l6n"
                                }
                            },
                            "id": "3ljBtXKkxFdkv6PTGYkM6R",
                            "type": "Asset",
                            "createdAt": "2020-03-30T14:41:53.625Z",
                            "updatedAt": "2020-03-30T14:41:53.625Z",
                            "environment": {
                                "sys": {
                                    "id": "master",
                                    "type": "Link",
                                    "linkType": "Environment"
                                }
                            },
                            "revision": 1,
                            "locale": "en-US"
                        },
                        "fields": {
                            "title": "saint-ann",
                            "file": {
                                "url": "//images.ctfassets.net/61geg9wy6l6n/3ljBtXKkxFdkv6PTGYkM6R/e42e5fddde2ef7af71e4ec865c5c2f7b/saint-ann.jpg",
                                "details": {
                                    "size": 147698,
                                    "image": {
                                        "width": 800,
                                        "height": 600
                                    }
                                },
                                "fileName": "saint-ann.jpg",
                                "contentType": "image/jpeg"
                            }
                        }
                    },
                    "url": "/homes/for-sale/jamaica/saint-ann",
                    "slug": "St. Ann"
                }
            },
            {
                "metadata": {
                    "tags": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "61geg9wy6l6n"
                        }
                    },
                    "id": "7bz5wb1bS1huDUK2U4fklw",
                    "type": "Entry",
                    "createdAt": "2020-03-30T14:25:51.926Z",
                    "updatedAt": "2020-09-14T02:46:06.653Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "revision": 5,
                    "contentType": {
                        "sys": {
                            "type": "Link",
                            "linkType": "ContentType",
                            "id": "regions"
                        }
                    },
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Westmoreland",
                    "description": "Westmoreland is a famed easygoing parish with a 7-mile beach strip in the popular town Negril. “West-end”, as it is typically called, encourages a standard sandals and t-shirt or beachwear uniform, especially along the hip strip where laidback is a core value. It caters largely to those whose idea of relaxation includes sun, sand, sea and a little rum.",
                    "image": {
                        "metadata": {
                            "tags": []
                        },
                        "sys": {
                            "space": {
                                "sys": {
                                    "type": "Link",
                                    "linkType": "Space",
                                    "id": "61geg9wy6l6n"
                                }
                            },
                            "id": "JdNMJxBaEtBNxocTuNdYE",
                            "type": "Asset",
                            "createdAt": "2020-03-30T14:42:15.856Z",
                            "updatedAt": "2020-03-30T14:42:15.856Z",
                            "environment": {
                                "sys": {
                                    "id": "master",
                                    "type": "Link",
                                    "linkType": "Environment"
                                }
                            },
                            "revision": 1,
                            "locale": "en-US"
                        },
                        "fields": {
                            "title": "westmoreland",
                            "file": {
                                "url": "//images.ctfassets.net/61geg9wy6l6n/JdNMJxBaEtBNxocTuNdYE/81828a9666e76ebecd9b74d3f5476f0e/westmoreland.jpg",
                                "details": {
                                    "size": 85884,
                                    "image": {
                                        "width": 800,
                                        "height": 600
                                    }
                                },
                                "fileName": "westmoreland.jpg",
                                "contentType": "image/jpeg"
                            }
                        }
                    },
                    "url": "/homes/for-sale/jamaica/westmoreland",
                    "slug": "Westmoreland"
                }
            },
            {
                "metadata": {
                    "tags": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "61geg9wy6l6n"
                        }
                    },
                    "id": "3rP1LEo8XdYt35cI1Z6FcT",
                    "type": "Entry",
                    "createdAt": "2020-03-30T14:02:51.656Z",
                    "updatedAt": "2020-09-14T02:45:52.131Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "revision": 5,
                    "contentType": {
                        "sys": {
                            "type": "Link",
                            "linkType": "ContentType",
                            "id": "regions"
                        }
                    },
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Saint Thomas",
                    "description": "A quiet and quaint region on the east coast of the island, St Thomas is most revered for its illustrious history, which arguably had set the precedence for freedom in the Caribbean during the 1800s. Today it is the retreat for many, boasting mineral baths, waterfalls, and black sand beaches, which are especially favored by surfers.",
                    "image": {
                        "metadata": {
                            "tags": []
                        },
                        "sys": {
                            "space": {
                                "sys": {
                                    "type": "Link",
                                    "linkType": "Space",
                                    "id": "61geg9wy6l6n"
                                }
                            },
                            "id": "sLl3ETzcRTBHvm9e8YGwD",
                            "type": "Asset",
                            "createdAt": "2020-03-30T14:42:36.210Z",
                            "updatedAt": "2020-07-16T15:11:14.918Z",
                            "environment": {
                                "sys": {
                                    "id": "master",
                                    "type": "Link",
                                    "linkType": "Environment"
                                }
                            },
                            "revision": 3,
                            "locale": "en-US"
                        },
                        "fields": {
                            "title": "saint-thomas",
                            "file": {
                                "url": "//images.ctfassets.net/61geg9wy6l6n/sLl3ETzcRTBHvm9e8YGwD/04b7e9c2bf1ed27a2ba60779f9e021a5/saint-thomas.jpg",
                                "details": {
                                    "size": 174972,
                                    "image": {
                                        "width": 800,
                                        "height": 537
                                    }
                                },
                                "fileName": "saint-thomas.jpg",
                                "contentType": "image/jpeg"
                            }
                        }
                    },
                    "url": "/homes/for-sale/jamaica/saint-thomas",
                    "slug": "St. Thomas"
                }
            },
            {
                "metadata": {
                    "tags": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "61geg9wy6l6n"
                        }
                    },
                    "id": "4nic50IUTxBJIhOifCP05O",
                    "type": "Entry",
                    "createdAt": "2020-03-30T14:02:23.521Z",
                    "updatedAt": "2020-09-14T02:45:36.538Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "revision": 5,
                    "contentType": {
                        "sys": {
                            "type": "Link",
                            "linkType": "ContentType",
                            "id": "regions"
                        }
                    },
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Saint James",
                    "description": "The local mecca for resorts and tourism activities, the parish of St. James is for many a first choice vacation destination. Its capital Montego Bay is recognized as the second city of the island, Kingston being the first. The Donald Sangster International Airport in the city is the busiest airport in the English speaking Caribbean. To no surprise, one in every four persons living in St. James is employed to the tourism industry.",
                    "image": {
                        "metadata": {
                            "tags": []
                        },
                        "sys": {
                            "space": {
                                "sys": {
                                    "type": "Link",
                                    "linkType": "Space",
                                    "id": "61geg9wy6l6n"
                                }
                            },
                            "id": "2JDfs7Ec0eJJdDJ2hzkMsQ",
                            "type": "Asset",
                            "createdAt": "2020-03-30T14:42:57.054Z",
                            "updatedAt": "2020-07-16T15:10:08.856Z",
                            "environment": {
                                "sys": {
                                    "id": "master",
                                    "type": "Link",
                                    "linkType": "Environment"
                                }
                            },
                            "revision": 3,
                            "locale": "en-US"
                        },
                        "fields": {
                            "title": "saint-james",
                            "file": {
                                "url": "//images.ctfassets.net/61geg9wy6l6n/2JDfs7Ec0eJJdDJ2hzkMsQ/8dc62c93be529a0723544b60b995f3e6/saint-james.jpg",
                                "details": {
                                    "size": 140515,
                                    "image": {
                                        "width": 800,
                                        "height": 600
                                    }
                                },
                                "fileName": "saint-james.jpg",
                                "contentType": "image/jpeg"
                            }
                        }
                    },
                    "url": "/homes/for-sale/jamaica/saint-james",
                    "slug": "St. James"
                }
            },
            {
                "metadata": {
                    "tags": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "61geg9wy6l6n"
                        }
                    },
                    "id": "6VK0lYthWXxp6iDauUwwvr",
                    "type": "Entry",
                    "createdAt": "2020-03-30T14:29:04.310Z",
                    "updatedAt": "2020-09-14T02:45:24.124Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "revision": 7,
                    "contentType": {
                        "sys": {
                            "type": "Link",
                            "linkType": "ContentType",
                            "id": "regions"
                        }
                    },
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Kingston",
                    "tagLine": "Vibrant Energy",
                    "description": "The heartbeat of the island and home of reggae music, Kingston is known for its high frequency and vibrant energy. The capital city is always teeming with activities from cultural events to the everyday bustle of city life.",
                    "image": {
                        "metadata": {
                            "tags": []
                        },
                        "sys": {
                            "space": {
                                "sys": {
                                    "type": "Link",
                                    "linkType": "Space",
                                    "id": "61geg9wy6l6n"
                                }
                            },
                            "id": "YrsyBd7mweGUaAOsk3QU8",
                            "type": "Asset",
                            "createdAt": "2020-03-30T14:37:48.781Z",
                            "updatedAt": "2020-03-30T14:37:48.781Z",
                            "environment": {
                                "sys": {
                                    "id": "master",
                                    "type": "Link",
                                    "linkType": "Environment"
                                }
                            },
                            "revision": 1,
                            "locale": "en-US"
                        },
                        "fields": {
                            "title": "kingston",
                            "file": {
                                "url": "//images.ctfassets.net/61geg9wy6l6n/YrsyBd7mweGUaAOsk3QU8/0494658c1dd56af9a9e6b8e8d8c45ded/kingston.jpg",
                                "details": {
                                    "size": 137158,
                                    "image": {
                                        "width": 800,
                                        "height": 600
                                    }
                                },
                                "fileName": "kingston.jpg",
                                "contentType": "image/jpeg"
                            }
                        }
                    },
                    "featured": true,
                    "url": "/homes/for-sale/jamaica/kingston",
                    "slug": "Kingston"
                }
            },
            {
                "metadata": {
                    "tags": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "61geg9wy6l6n"
                        }
                    },
                    "id": "1eLRSqxpGvTsYycJviysMS",
                    "type": "Entry",
                    "createdAt": "2020-03-30T14:30:20.206Z",
                    "updatedAt": "2020-06-19T03:23:46.061Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "revision": 6,
                    "contentType": {
                        "sys": {
                            "type": "Link",
                            "linkType": "ContentType",
                            "id": "regions"
                        }
                    },
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Hanover",
                    "tagLine": "Luxury",
                    "description": "Hanover is the smallest parish in Jamaica and is often overlooked for it’s popular resort neighbours St. James and Trelawny. Nevertheless, it has it own beauty and charm, which is easy to discover as it shares the famed Negril Strip with Westmoreland. It is host to some of the most renowned luxury hotels on the island including Tryall Golf Club, which currently houses a 200-year-old water wheel from the times of slavery.",
                    "image": {
                        "metadata": {
                            "tags": []
                        },
                        "sys": {
                            "space": {
                                "sys": {
                                    "type": "Link",
                                    "linkType": "Space",
                                    "id": "61geg9wy6l6n"
                                }
                            },
                            "id": "7h8XSfmfSC0oFxjJ6mdCSm",
                            "type": "Asset",
                            "createdAt": "2020-03-30T14:39:02.688Z",
                            "updatedAt": "2020-03-30T14:39:02.688Z",
                            "environment": {
                                "sys": {
                                    "id": "master",
                                    "type": "Link",
                                    "linkType": "Environment"
                                }
                            },
                            "revision": 1,
                            "locale": "en-US"
                        },
                        "fields": {
                            "title": "hanover",
                            "file": {
                                "url": "//images.ctfassets.net/61geg9wy6l6n/7h8XSfmfSC0oFxjJ6mdCSm/f24fb4819f2e2e5094996ad2cdb7bf97/hanover.jpg",
                                "details": {
                                    "size": 145392,
                                    "image": {
                                        "width": 800,
                                        "height": 533
                                    }
                                },
                                "fileName": "hanover.jpg",
                                "contentType": "image/jpeg"
                            }
                        }
                    },
                    "featured": true,
                    "url": "/homes/for-sale/jamaica/hanover",
                    "slug": "Hanover"
                }
            },
            {
                "metadata": {
                    "tags": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "61geg9wy6l6n"
                        }
                    },
                    "id": "0OccwAm4qaRWxi1Mg2Edo",
                    "type": "Entry",
                    "createdAt": "2020-03-30T14:29:33.840Z",
                    "updatedAt": "2020-06-19T03:23:35.757Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "revision": 6,
                    "contentType": {
                        "sys": {
                            "type": "Link",
                            "linkType": "ContentType",
                            "id": "regions"
                        }
                    },
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Saint Andrew",
                    "tagLine": "Business Hub",
                    "description": "Together with its sister parish, Kingston, this area is the most populated on the island in spite of being the second smallest parish. St. Andrew is not only the residential haven for many Jamaicans but is also known for being the business and commerce hub of the island.",
                    "image": {
                        "metadata": {
                            "tags": []
                        },
                        "sys": {
                            "space": {
                                "sys": {
                                    "type": "Link",
                                    "linkType": "Space",
                                    "id": "61geg9wy6l6n"
                                }
                            },
                            "id": "1sfbWqEIHfvUxzddy4Dal5",
                            "type": "Asset",
                            "createdAt": "2020-03-30T14:38:39.917Z",
                            "updatedAt": "2020-03-30T14:38:39.917Z",
                            "environment": {
                                "sys": {
                                    "id": "master",
                                    "type": "Link",
                                    "linkType": "Environment"
                                }
                            },
                            "revision": 1,
                            "locale": "en-US"
                        },
                        "fields": {
                            "title": "saint-andrew",
                            "file": {
                                "url": "//images.ctfassets.net/61geg9wy6l6n/1sfbWqEIHfvUxzddy4Dal5/6b424c35f3aa578959bbe119c5fdffd0/saint-andrew.jpg",
                                "details": {
                                    "size": 164221,
                                    "image": {
                                        "width": 800,
                                        "height": 600
                                    }
                                },
                                "fileName": "saint-andrew.jpg",
                                "contentType": "image/jpeg"
                            }
                        }
                    },
                    "featured": true,
                    "url": "/homes/for-sale/jamaica/saint-andrew",
                    "slug": "St. Andrew"
                }
            }
        ],
        "includes": {
            "Asset": [
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "1FNoHrtEV70e3kGMxlyvbl",
                        "type": "Asset",
                        "createdAt": "2020-03-30T14:41:31.641Z",
                        "updatedAt": "2020-03-30T14:41:31.641Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "saint-catherine",
                        "file": {
                            "url": "//images.ctfassets.net/61geg9wy6l6n/1FNoHrtEV70e3kGMxlyvbl/33221df40d3343e05fce30ca196114c0/saint-catherine.jpg",
                            "details": {
                                "size": 150141,
                                "image": {
                                    "width": 800,
                                    "height": 533
                                }
                            },
                            "fileName": "saint-catherine.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "1sfbWqEIHfvUxzddy4Dal5",
                        "type": "Asset",
                        "createdAt": "2020-03-30T14:38:39.917Z",
                        "updatedAt": "2020-03-30T14:38:39.917Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "saint-andrew",
                        "file": {
                            "url": "//images.ctfassets.net/61geg9wy6l6n/1sfbWqEIHfvUxzddy4Dal5/6b424c35f3aa578959bbe119c5fdffd0/saint-andrew.jpg",
                            "details": {
                                "size": 164221,
                                "image": {
                                    "width": 800,
                                    "height": 600
                                }
                            },
                            "fileName": "saint-andrew.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "2JDfs7Ec0eJJdDJ2hzkMsQ",
                        "type": "Asset",
                        "createdAt": "2020-03-30T14:42:57.054Z",
                        "updatedAt": "2020-07-16T15:10:08.856Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 3,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "saint-james",
                        "file": {
                            "url": "//images.ctfassets.net/61geg9wy6l6n/2JDfs7Ec0eJJdDJ2hzkMsQ/8dc62c93be529a0723544b60b995f3e6/saint-james.jpg",
                            "details": {
                                "size": 140515,
                                "image": {
                                    "width": 800,
                                    "height": 600
                                }
                            },
                            "fileName": "saint-james.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "3SMrPYYVyC4tw1Q3DGNKXF",
                        "type": "Asset",
                        "createdAt": "2020-03-30T14:38:15.912Z",
                        "updatedAt": "2020-07-16T15:10:39.209Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 3,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "clarendon",
                        "file": {
                            "url": "//images.ctfassets.net/61geg9wy6l6n/3SMrPYYVyC4tw1Q3DGNKXF/3034b765f8a7fd4abfea8cb6ac128626/clarendon.jpg",
                            "details": {
                                "size": 65232,
                                "image": {
                                    "width": 800,
                                    "height": 534
                                }
                            },
                            "fileName": "clarendon.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "3cQNxqZAI4Rr0wk4EHpRA7",
                        "type": "Asset",
                        "createdAt": "2020-03-30T14:40:02.199Z",
                        "updatedAt": "2020-03-30T14:40:02.199Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "portland",
                        "file": {
                            "url": "//images.ctfassets.net/61geg9wy6l6n/3cQNxqZAI4Rr0wk4EHpRA7/e4708de9b9745becd478182ab578d07d/portland.jpg",
                            "details": {
                                "size": 160851,
                                "image": {
                                    "width": 800,
                                    "height": 600
                                }
                            },
                            "fileName": "portland.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "3ljBtXKkxFdkv6PTGYkM6R",
                        "type": "Asset",
                        "createdAt": "2020-03-30T14:41:53.625Z",
                        "updatedAt": "2020-03-30T14:41:53.625Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "saint-ann",
                        "file": {
                            "url": "//images.ctfassets.net/61geg9wy6l6n/3ljBtXKkxFdkv6PTGYkM6R/e42e5fddde2ef7af71e4ec865c5c2f7b/saint-ann.jpg",
                            "details": {
                                "size": 147698,
                                "image": {
                                    "width": 800,
                                    "height": 600
                                }
                            },
                            "fileName": "saint-ann.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "4871MbEQMt4m1OJC1Gy8RG",
                        "type": "Asset",
                        "createdAt": "2020-03-30T14:40:21.827Z",
                        "updatedAt": "2020-03-30T14:40:21.827Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "saint-mary",
                        "file": {
                            "url": "//images.ctfassets.net/61geg9wy6l6n/4871MbEQMt4m1OJC1Gy8RG/2276c0a96c651d492be995902b525029/saint-mary.jpg",
                            "details": {
                                "size": 77947,
                                "image": {
                                    "width": 800,
                                    "height": 450
                                }
                            },
                            "fileName": "saint-mary.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "6FJcMPnbYOYFiD7OoXwnk2",
                        "type": "Asset",
                        "createdAt": "2020-03-30T14:40:46.644Z",
                        "updatedAt": "2020-03-30T14:40:46.644Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "trelawny",
                        "file": {
                            "url": "//images.ctfassets.net/61geg9wy6l6n/6FJcMPnbYOYFiD7OoXwnk2/5b54f9c91e28a6dcfe69df46e12bdaa5/trelawny.jpg",
                            "details": {
                                "size": 144313,
                                "image": {
                                    "width": 800,
                                    "height": 600
                                }
                            },
                            "fileName": "trelawny.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "7FV0XQp32ioMO8cUQ3w5nU",
                        "type": "Asset",
                        "createdAt": "2020-03-30T14:41:07.271Z",
                        "updatedAt": "2020-03-30T14:41:07.271Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "saint-elizabeth",
                        "file": {
                            "url": "//images.ctfassets.net/61geg9wy6l6n/7FV0XQp32ioMO8cUQ3w5nU/4ed69192579a10ab1c532b7d89444b08/saint-elizabeth.jpg",
                            "details": {
                                "size": 161740,
                                "image": {
                                    "width": 800,
                                    "height": 600
                                }
                            },
                            "fileName": "saint-elizabeth.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "7h8XSfmfSC0oFxjJ6mdCSm",
                        "type": "Asset",
                        "createdAt": "2020-03-30T14:39:02.688Z",
                        "updatedAt": "2020-03-30T14:39:02.688Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "hanover",
                        "file": {
                            "url": "//images.ctfassets.net/61geg9wy6l6n/7h8XSfmfSC0oFxjJ6mdCSm/f24fb4819f2e2e5094996ad2cdb7bf97/hanover.jpg",
                            "details": {
                                "size": 145392,
                                "image": {
                                    "width": 800,
                                    "height": 533
                                }
                            },
                            "fileName": "hanover.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "JdNMJxBaEtBNxocTuNdYE",
                        "type": "Asset",
                        "createdAt": "2020-03-30T14:42:15.856Z",
                        "updatedAt": "2020-03-30T14:42:15.856Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "westmoreland",
                        "file": {
                            "url": "//images.ctfassets.net/61geg9wy6l6n/JdNMJxBaEtBNxocTuNdYE/81828a9666e76ebecd9b74d3f5476f0e/westmoreland.jpg",
                            "details": {
                                "size": 85884,
                                "image": {
                                    "width": 800,
                                    "height": 600
                                }
                            },
                            "fileName": "westmoreland.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "YrsyBd7mweGUaAOsk3QU8",
                        "type": "Asset",
                        "createdAt": "2020-03-30T14:37:48.781Z",
                        "updatedAt": "2020-03-30T14:37:48.781Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "kingston",
                        "file": {
                            "url": "//images.ctfassets.net/61geg9wy6l6n/YrsyBd7mweGUaAOsk3QU8/0494658c1dd56af9a9e6b8e8d8c45ded/kingston.jpg",
                            "details": {
                                "size": 137158,
                                "image": {
                                    "width": 800,
                                    "height": 600
                                }
                            },
                            "fileName": "kingston.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "emjuPr7paOkH8FV8nqF2T",
                        "type": "Asset",
                        "createdAt": "2020-03-30T14:39:28.140Z",
                        "updatedAt": "2020-03-30T14:39:28.140Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "manchester",
                        "file": {
                            "url": "//images.ctfassets.net/61geg9wy6l6n/emjuPr7paOkH8FV8nqF2T/817bfa60e2c39339d4722aa174cc582b/manchester.jpg",
                            "details": {
                                "size": 127780,
                                "image": {
                                    "width": 800,
                                    "height": 533
                                }
                            },
                            "fileName": "manchester.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "sLl3ETzcRTBHvm9e8YGwD",
                        "type": "Asset",
                        "createdAt": "2020-03-30T14:42:36.210Z",
                        "updatedAt": "2020-07-16T15:11:14.918Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 3,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "saint-thomas",
                        "file": {
                            "url": "//images.ctfassets.net/61geg9wy6l6n/sLl3ETzcRTBHvm9e8YGwD/04b7e9c2bf1ed27a2ba60779f9e021a5/saint-thomas.jpg",
                            "details": {
                                "size": 174972,
                                "image": {
                                    "width": 800,
                                    "height": 537
                                }
                            },
                            "fileName": "saint-thomas.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                }
            ]
        }
      };
      dispatch(slice.actions.getRegionsContentSuccess(res));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getRegionContent(name) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
     const res = await contentfulClient.getEntries({ content_type: 'regions', 'fields.slug': name  });
      dispatch(slice.actions.getRegionContentSuccess(res));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPropertyTypes() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const { items } = await contentfulClient.getEntries({ content_type: 'propertyTypes' });
      const items = [
        {
            "metadata": {
                "tags": []
            },
            "sys": {
                "space": {
                    "sys": {
                        "type": "Link",
                        "linkType": "Space",
                        "id": "61geg9wy6l6n"
                    }
                },
                "id": "6U7O9gCzR3kFiUqy2YEh4q",
                "type": "Entry",
                "createdAt": "2020-04-09T01:19:47.569Z",
                "updatedAt": "2020-04-09T01:23:14.917Z",
                "environment": {
                    "sys": {
                        "id": "master",
                        "type": "Link",
                        "linkType": "Environment"
                    }
                },
                "revision": 2,
                "contentType": {
                    "sys": {
                        "type": "Link",
                        "linkType": "ContentType",
                        "id": "propertyTypes"
                    }
                },
                "locale": "en-US"
            },
            "fields": {
                "label": "Villa",
                "value": "villa"
            }
        },
        {
            "metadata": {
                "tags": []
            },
            "sys": {
                "space": {
                    "sys": {
                        "type": "Link",
                        "linkType": "Space",
                        "id": "61geg9wy6l6n"
                    }
                },
                "id": "yTnRnx5bdXzK12wRwHTq8",
                "type": "Entry",
                "createdAt": "2020-04-09T01:17:43.916Z",
                "updatedAt": "2020-04-09T01:22:32.156Z",
                "environment": {
                    "sys": {
                        "id": "master",
                        "type": "Link",
                        "linkType": "Environment"
                    }
                },
                "revision": 2,
                "contentType": {
                    "sys": {
                        "type": "Link",
                        "linkType": "ContentType",
                        "id": "propertyTypes"
                    }
                },
                "locale": "en-US"
            },
            "fields": {
                "label": "House",
                "value": "home"
            }
        },
        {
            "metadata": {
                "tags": []
            },
            "sys": {
                "space": {
                    "sys": {
                        "type": "Link",
                        "linkType": "Space",
                        "id": "61geg9wy6l6n"
                    }
                },
                "id": "7MENqWpcJMDNKSTRcZviIA",
                "type": "Entry",
                "createdAt": "2020-04-09T01:22:16.923Z",
                "updatedAt": "2020-04-09T01:22:16.923Z",
                "environment": {
                    "sys": {
                        "id": "master",
                        "type": "Link",
                        "linkType": "Environment"
                    }
                },
                "revision": 1,
                "contentType": {
                    "sys": {
                        "type": "Link",
                        "linkType": "ContentType",
                        "id": "propertyTypes"
                    }
                },
                "locale": "en-US"
            },
            "fields": {
                "label": "Agricultural Lot",
                "value": "farm"
            }
        },
        {
            "metadata": {
                "tags": []
            },
            "sys": {
                "space": {
                    "sys": {
                        "type": "Link",
                        "linkType": "Space",
                        "id": "61geg9wy6l6n"
                    }
                },
                "id": "7w4xmdVZPobu2MxAnXDYFX",
                "type": "Entry",
                "createdAt": "2020-04-09T01:20:41.348Z",
                "updatedAt": "2020-04-09T01:20:41.348Z",
                "environment": {
                    "sys": {
                        "id": "master",
                        "type": "Link",
                        "linkType": "Environment"
                    }
                },
                "revision": 1,
                "contentType": {
                    "sys": {
                        "type": "Link",
                        "linkType": "ContentType",
                        "id": "propertyTypes"
                    }
                },
                "locale": "en-US"
            },
            "fields": {
                "label": "Development Land",
                "value": "land"
            }
        },
        {
            "metadata": {
                "tags": []
            },
            "sys": {
                "space": {
                    "sys": {
                        "type": "Link",
                        "linkType": "Space",
                        "id": "61geg9wy6l6n"
                    }
                },
                "id": "5p7JeM5QZpod2I9e1NBXZI",
                "type": "Entry",
                "createdAt": "2020-04-09T01:20:17.403Z",
                "updatedAt": "2020-04-09T01:20:17.403Z",
                "environment": {
                    "sys": {
                        "id": "master",
                        "type": "Link",
                        "linkType": "Environment"
                    }
                },
                "revision": 1,
                "contentType": {
                    "sys": {
                        "type": "Link",
                        "linkType": "ContentType",
                        "id": "propertyTypes"
                    }
                },
                "locale": "en-US"
            },
            "fields": {
                "label": "Residential Lot",
                "value": "lot"
            }
        },
        {
            "metadata": {
                "tags": []
            },
            "sys": {
                "space": {
                    "sys": {
                        "type": "Link",
                        "linkType": "Space",
                        "id": "61geg9wy6l6n"
                    }
                },
                "id": "1qKPkumvEcXd7KBXMsieK0",
                "type": "Entry",
                "createdAt": "2020-04-09T01:17:25.003Z",
                "updatedAt": "2020-04-09T01:17:25.003Z",
                "environment": {
                    "sys": {
                        "id": "master",
                        "type": "Link",
                        "linkType": "Environment"
                    }
                },
                "revision": 1,
                "contentType": {
                    "sys": {
                        "type": "Link",
                        "linkType": "ContentType",
                        "id": "propertyTypes"
                    }
                },
                "locale": "en-US"
            },
            "fields": {
                "label": "Townhouse",
                "value": "townhouse"
            }
        },
        {
            "metadata": {
                "tags": []
            },
            "sys": {
                "space": {
                    "sys": {
                        "type": "Link",
                        "linkType": "Space",
                        "id": "61geg9wy6l6n"
                    }
                },
                "id": "50mJd5H2U5xb3N9QVc5M1W",
                "type": "Entry",
                "createdAt": "2020-04-09T01:16:54.881Z",
                "updatedAt": "2020-04-09T01:16:54.881Z",
                "environment": {
                    "sys": {
                        "id": "master",
                        "type": "Link",
                        "linkType": "Environment"
                    }
                },
                "revision": 1,
                "contentType": {
                    "sys": {
                        "type": "Link",
                        "linkType": "ContentType",
                        "id": "propertyTypes"
                    }
                },
                "locale": "en-US"
            },
            "fields": {
                "label": "Apartment",
                "value": "apartment"
            }
        }
      ];
      const categoryOptions = items.map((type) => type.fields);
      dispatch(slice.actions.getPropertyTypesSuccess(categoryOptions));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPropertyFeatures() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
     // const { items } = await contentfulClient.getEntries({ content_type: 'propertyFeature' });
     const items = [
      {
          "metadata": {
              "tags": []
          },
          "sys": {
              "space": {
                  "sys": {
                      "type": "Link",
                      "linkType": "Space",
                      "id": "61geg9wy6l6n"
                  }
              },
              "id": "4UCWcPUYSHNuehSuBXnbAS",
              "type": "Entry",
              "createdAt": "2020-08-07T02:29:27.721Z",
              "updatedAt": "2020-08-07T02:29:27.721Z",
              "environment": {
                  "sys": {
                      "id": "master",
                      "type": "Link",
                      "linkType": "Environment"
                  }
              },
              "revision": 1,
              "contentType": {
                  "sys": {
                      "type": "Link",
                      "linkType": "ContentType",
                      "id": "propertyFeature"
                  }
              },
              "locale": "en-US"
          },
          "fields": {
              "label": "River View",
              "value": "view-river"
          }
      },
      {
          "metadata": {
              "tags": []
          },
          "sys": {
              "space": {
                  "sys": {
                      "type": "Link",
                      "linkType": "Space",
                      "id": "61geg9wy6l6n"
                  }
              },
              "id": "5vRP0dztSy3s241nz6p14C",
              "type": "Entry",
              "createdAt": "2020-08-07T02:28:43.727Z",
              "updatedAt": "2020-08-07T02:28:43.727Z",
              "environment": {
                  "sys": {
                      "id": "master",
                      "type": "Link",
                      "linkType": "Environment"
                  }
              },
              "revision": 1,
              "contentType": {
                  "sys": {
                      "type": "Link",
                      "linkType": "ContentType",
                      "id": "propertyFeature"
                  }
              },
              "locale": "en-US"
          },
          "fields": {
              "label": "Ocean View",
              "value": "view-ocean"
          }
      },
      {
          "metadata": {
              "tags": []
          },
          "sys": {
              "space": {
                  "sys": {
                      "type": "Link",
                      "linkType": "Space",
                      "id": "61geg9wy6l6n"
                  }
              },
              "id": "C4OwHq9xYtqjXQ3Tc0IWP",
              "type": "Entry",
              "createdAt": "2020-08-07T02:28:10.925Z",
              "updatedAt": "2020-08-07T02:28:10.925Z",
              "environment": {
                  "sys": {
                      "id": "master",
                      "type": "Link",
                      "linkType": "Environment"
                  }
              },
              "revision": 1,
              "contentType": {
                  "sys": {
                      "type": "Link",
                      "linkType": "ContentType",
                      "id": "propertyFeature"
                  }
              },
              "locale": "en-US"
          },
          "fields": {
              "label": "Mountain View",
              "value": "view-mountain"
          }
      },
      {
          "metadata": {
              "tags": []
          },
          "sys": {
              "space": {
                  "sys": {
                      "type": "Link",
                      "linkType": "Space",
                      "id": "61geg9wy6l6n"
                  }
              },
              "id": "nLn09ivAUJp6Fc2ZE8Oj4",
              "type": "Entry",
              "createdAt": "2020-08-07T02:27:46.159Z",
              "updatedAt": "2020-08-07T02:27:46.159Z",
              "environment": {
                  "sys": {
                      "id": "master",
                      "type": "Link",
                      "linkType": "Environment"
                  }
              },
              "revision": 1,
              "contentType": {
                  "sys": {
                      "type": "Link",
                      "linkType": "ContentType",
                      "id": "propertyFeature"
                  }
              },
              "locale": "en-US"
          },
          "fields": {
              "label": "City View",
              "value": "view-city"
          }
      },
      {
          "metadata": {
              "tags": []
          },
          "sys": {
              "space": {
                  "sys": {
                      "type": "Link",
                      "linkType": "Space",
                      "id": "61geg9wy6l6n"
                  }
              },
              "id": "3D2IR0G2VCfGUr1cAtN3Hl",
              "type": "Entry",
              "createdAt": "2020-08-07T02:27:08.241Z",
              "updatedAt": "2020-08-07T02:27:08.241Z",
              "environment": {
                  "sys": {
                      "id": "master",
                      "type": "Link",
                      "linkType": "Environment"
                  }
              },
              "revision": 1,
              "contentType": {
                  "sys": {
                      "type": "Link",
                      "linkType": "ContentType",
                      "id": "propertyFeature"
                  }
              },
              "locale": "en-US"
          },
          "fields": {
              "label": "Bay",
              "value": "bay"
          }
      },
      {
          "metadata": {
              "tags": []
          },
          "sys": {
              "space": {
                  "sys": {
                      "type": "Link",
                      "linkType": "Space",
                      "id": "61geg9wy6l6n"
                  }
              },
              "id": "75x4rOs64nhkn4LaE302aK",
              "type": "Entry",
              "createdAt": "2020-08-07T02:26:45.735Z",
              "updatedAt": "2020-08-07T02:26:45.735Z",
              "environment": {
                  "sys": {
                      "id": "master",
                      "type": "Link",
                      "linkType": "Environment"
                  }
              },
              "revision": 1,
              "contentType": {
                  "sys": {
                      "type": "Link",
                      "linkType": "ContentType",
                      "id": "propertyFeature"
                  }
              },
              "locale": "en-US"
          },
          "fields": {
              "label": "Harbour",
              "value": "harbour"
          }
      },
      {
          "metadata": {
              "tags": []
          },
          "sys": {
              "space": {
                  "sys": {
                      "type": "Link",
                      "linkType": "Space",
                      "id": "61geg9wy6l6n"
                  }
              },
              "id": "s4stj89vRwVCSahsxEq5V",
              "type": "Entry",
              "createdAt": "2020-08-07T02:26:10.441Z",
              "updatedAt": "2020-08-07T02:26:10.441Z",
              "environment": {
                  "sys": {
                      "id": "master",
                      "type": "Link",
                      "linkType": "Environment"
                  }
              },
              "revision": 1,
              "contentType": {
                  "sys": {
                      "type": "Link",
                      "linkType": "ContentType",
                      "id": "propertyFeature"
                  }
              },
              "locale": "en-US"
          },
          "fields": {
              "label": "Marina Nearby",
              "value": "marina-nearby"
          }
      },
      {
          "metadata": {
              "tags": []
          },
          "sys": {
              "space": {
                  "sys": {
                      "type": "Link",
                      "linkType": "Space",
                      "id": "61geg9wy6l6n"
                  }
              },
              "id": "3fSMS3S2xp00D5IRutySLh",
              "type": "Entry",
              "createdAt": "2020-08-07T02:25:45.944Z",
              "updatedAt": "2020-08-07T02:25:45.944Z",
              "environment": {
                  "sys": {
                      "id": "master",
                      "type": "Link",
                      "linkType": "Environment"
                  }
              },
              "revision": 1,
              "contentType": {
                  "sys": {
                      "type": "Link",
                      "linkType": "ContentType",
                      "id": "propertyFeature"
                  }
              },
              "locale": "en-US"
          },
          "fields": {
              "label": "Private Island",
              "value": "private-island"
          }
      },
      {
          "metadata": {
              "tags": []
          },
          "sys": {
              "space": {
                  "sys": {
                      "type": "Link",
                      "linkType": "Space",
                      "id": "61geg9wy6l6n"
                  }
              },
              "id": "6OG2tHTsQ4jjETvXsxh89D",
              "type": "Entry",
              "createdAt": "2020-08-07T02:25:18.922Z",
              "updatedAt": "2020-08-07T02:25:18.922Z",
              "environment": {
                  "sys": {
                      "id": "master",
                      "type": "Link",
                      "linkType": "Environment"
                  }
              },
              "revision": 1,
              "contentType": {
                  "sys": {
                      "type": "Link",
                      "linkType": "ContentType",
                      "id": "propertyFeature"
                  }
              },
              "locale": "en-US"
          },
          "fields": {
              "label": "Recreational",
              "value": "recreational"
          }
      },
      {
          "metadata": {
              "tags": []
          },
          "sys": {
              "space": {
                  "sys": {
                      "type": "Link",
                      "linkType": "Space",
                      "id": "61geg9wy6l6n"
                  }
              },
              "id": "yS1QhvPpTYQWhhvKR8JYj",
              "type": "Entry",
              "createdAt": "2020-08-07T02:24:53.713Z",
              "updatedAt": "2020-08-07T02:24:53.713Z",
              "environment": {
                  "sys": {
                      "id": "master",
                      "type": "Link",
                      "linkType": "Environment"
                  }
              },
              "revision": 1,
              "contentType": {
                  "sys": {
                      "type": "Link",
                      "linkType": "ContentType",
                      "id": "propertyFeature"
                  }
              },
              "locale": "en-US"
          },
          "fields": {
              "label": "Shopping Nearby",
              "value": "shopping-nearby"
          }
      },
      {
          "metadata": {
              "tags": []
          },
          "sys": {
              "space": {
                  "sys": {
                      "type": "Link",
                      "linkType": "Space",
                      "id": "61geg9wy6l6n"
                  }
              },
              "id": "BZjEQ5ZzM42S28OTL1pMQ",
              "type": "Entry",
              "createdAt": "2020-08-07T02:24:17.237Z",
              "updatedAt": "2020-08-07T02:24:17.237Z",
              "environment": {
                  "sys": {
                      "id": "master",
                      "type": "Link",
                      "linkType": "Environment"
                  }
              },
              "revision": 1,
              "contentType": {
                  "sys": {
                      "type": "Link",
                      "linkType": "ContentType",
                      "id": "propertyFeature"
                  }
              },
              "locale": "en-US"
          },
          "fields": {
              "label": "Lake View",
              "value": "view-lake"
          }
      },
      {
          "metadata": {
              "tags": []
          },
          "sys": {
              "space": {
                  "sys": {
                      "type": "Link",
                      "linkType": "Space",
                      "id": "61geg9wy6l6n"
                  }
              },
              "id": "1UfNqVwXA8hRNzooHpzA48",
              "type": "Entry",
              "createdAt": "2020-08-07T02:23:03.123Z",
              "updatedAt": "2020-08-07T02:23:03.123Z",
              "environment": {
                  "sys": {
                      "id": "master",
                      "type": "Link",
                      "linkType": "Environment"
                  }
              },
              "revision": 1,
              "contentType": {
                  "sys": {
                      "type": "Link",
                      "linkType": "ContentType",
                      "id": "propertyFeature"
                  }
              },
              "locale": "en-US"
          },
          "fields": {
              "label": "Waterfront",
              "value": "waterfront"
          }
      },
      {
          "metadata": {
              "tags": []
          },
          "sys": {
              "space": {
                  "sys": {
                      "type": "Link",
                      "linkType": "Space",
                      "id": "61geg9wy6l6n"
                  }
              },
              "id": "17W5Fkx73LalKsEdpLuTAr",
              "type": "Entry",
              "createdAt": "2020-08-07T02:22:34.527Z",
              "updatedAt": "2020-08-07T02:22:34.527Z",
              "environment": {
                  "sys": {
                      "id": "master",
                      "type": "Link",
                      "linkType": "Environment"
                  }
              },
              "revision": 1,
              "contentType": {
                  "sys": {
                      "type": "Link",
                      "linkType": "ContentType",
                      "id": "propertyFeature"
                  }
              },
              "locale": "en-US"
          },
          "fields": {
              "label": "Wooded Area",
              "value": "wooded-area"
          }
      },
      {
          "metadata": {
              "tags": []
          },
          "sys": {
              "space": {
                  "sys": {
                      "type": "Link",
                      "linkType": "Space",
                      "id": "61geg9wy6l6n"
                  }
              },
              "id": "4tCNGkIVhPhwMVb6vQmeA5",
              "type": "Entry",
              "createdAt": "2020-08-07T02:21:38.486Z",
              "updatedAt": "2020-08-07T02:21:38.486Z",
              "environment": {
                  "sys": {
                      "id": "master",
                      "type": "Link",
                      "linkType": "Environment"
                  }
              },
              "revision": 1,
              "contentType": {
                  "sys": {
                      "type": "Link",
                      "linkType": "ContentType",
                      "id": "propertyFeature"
                  }
              },
              "locale": "en-US"
          },
          "fields": {
              "label": "Level Lot",
              "value": "level-lot"
          }
      },
      {
          "metadata": {
              "tags": []
          },
          "sys": {
              "space": {
                  "sys": {
                      "type": "Link",
                      "linkType": "Space",
                      "id": "61geg9wy6l6n"
                  }
              },
              "id": "1otUUKVg7XsviMY24FNkeZ",
              "type": "Entry",
              "createdAt": "2020-08-07T02:21:16.267Z",
              "updatedAt": "2020-08-07T02:21:16.267Z",
              "environment": {
                  "sys": {
                      "id": "master",
                      "type": "Link",
                      "linkType": "Environment"
                  }
              },
              "revision": 1,
              "contentType": {
                  "sys": {
                      "type": "Link",
                      "linkType": "ContentType",
                      "id": "propertyFeature"
                  }
              },
              "locale": "en-US"
          },
          "fields": {
              "label": "Landscaped",
              "value": "landscaped"
          }
      },
      {
          "metadata": {
              "tags": []
          },
          "sys": {
              "space": {
                  "sys": {
                      "type": "Link",
                      "linkType": "Space",
                      "id": "61geg9wy6l6n"
                  }
              },
              "id": "3pcEYmRTNY6AKtVtLgnjgN",
              "type": "Entry",
              "createdAt": "2020-08-07T02:20:57.038Z",
              "updatedAt": "2020-08-07T02:20:57.038Z",
              "environment": {
                  "sys": {
                      "id": "master",
                      "type": "Link",
                      "linkType": "Environment"
                  }
              },
              "revision": 1,
              "contentType": {
                  "sys": {
                      "type": "Link",
                      "linkType": "ContentType",
                      "id": "propertyFeature"
                  }
              },
              "locale": "en-US"
          },
          "fields": {
              "label": "Hillside",
              "value": "hillside"
          }
      },
      {
          "metadata": {
              "tags": []
          },
          "sys": {
              "space": {
                  "sys": {
                      "type": "Link",
                      "linkType": "Space",
                      "id": "61geg9wy6l6n"
                  }
              },
              "id": "5kd3HdofNhXA5gC1aqHzEc",
              "type": "Entry",
              "createdAt": "2020-08-07T02:20:37.005Z",
              "updatedAt": "2020-08-07T02:20:37.005Z",
              "environment": {
                  "sys": {
                      "id": "master",
                      "type": "Link",
                      "linkType": "Environment"
                  }
              },
              "revision": 1,
              "contentType": {
                  "sys": {
                      "type": "Link",
                      "linkType": "ContentType",
                      "id": "propertyFeature"
                  }
              },
              "locale": "en-US"
          },
          "fields": {
              "label": "Highway Access",
              "value": "highway-access"
          }
      },
      {
          "metadata": {
              "tags": []
          },
          "sys": {
              "space": {
                  "sys": {
                      "type": "Link",
                      "linkType": "Space",
                      "id": "61geg9wy6l6n"
                  }
              },
              "id": "1MEPtcdgt7IlCISYLcWi8K",
              "type": "Entry",
              "createdAt": "2020-08-07T02:20:13.367Z",
              "updatedAt": "2020-08-07T02:20:13.367Z",
              "environment": {
                  "sys": {
                      "id": "master",
                      "type": "Link",
                      "linkType": "Environment"
                  }
              },
              "revision": 1,
              "contentType": {
                  "sys": {
                      "type": "Link",
                      "linkType": "ContentType",
                      "id": "propertyFeature"
                  }
              },
              "locale": "en-US"
          },
          "fields": {
              "label": "Golf Course Nearby",
              "value": "golf-course-nearby"
          }
      },
      {
          "metadata": {
              "tags": []
          },
          "sys": {
              "space": {
                  "sys": {
                      "type": "Link",
                      "linkType": "Space",
                      "id": "61geg9wy6l6n"
                  }
              },
              "id": "7zMhoob5wdclOY0YEDATl2",
              "type": "Entry",
              "createdAt": "2020-08-07T02:19:41.484Z",
              "updatedAt": "2020-08-07T02:19:41.484Z",
              "environment": {
                  "sys": {
                      "id": "master",
                      "type": "Link",
                      "linkType": "Environment"
                  }
              },
              "revision": 1,
              "contentType": {
                  "sys": {
                      "type": "Link",
                      "linkType": "ContentType",
                      "id": "propertyFeature"
                  }
              },
              "locale": "en-US"
          },
          "fields": {
              "label": "Gated Community",
              "value": "gated-community"
          }
      },
      {
          "metadata": {
              "tags": []
          },
          "sys": {
              "space": {
                  "sys": {
                      "type": "Link",
                      "linkType": "Space",
                      "id": "61geg9wy6l6n"
                  }
              },
              "id": "7gRwf4eIGku1qLc0G5DGi0",
              "type": "Entry",
              "createdAt": "2020-08-07T02:19:00.994Z",
              "updatedAt": "2020-08-07T02:19:00.994Z",
              "environment": {
                  "sys": {
                      "id": "master",
                      "type": "Link",
                      "linkType": "Environment"
                  }
              },
              "revision": 1,
              "contentType": {
                  "sys": {
                      "type": "Link",
                      "linkType": "ContentType",
                      "id": "propertyFeature"
                  }
              },
              "locale": "en-US"
          },
          "fields": {
              "label": "Family Oriented",
              "value": "family-oriented"
          }
      },
      {
          "metadata": {
              "tags": []
          },
          "sys": {
              "space": {
                  "sys": {
                      "type": "Link",
                      "linkType": "Space",
                      "id": "61geg9wy6l6n"
                  }
              },
              "id": "20bMtbrCAsD67CFpBiSXuJ",
              "type": "Entry",
              "createdAt": "2020-08-07T02:18:24.584Z",
              "updatedAt": "2020-08-07T02:18:24.584Z",
              "environment": {
                  "sys": {
                      "id": "master",
                      "type": "Link",
                      "linkType": "Environment"
                  }
              },
              "revision": 1,
              "contentType": {
                  "sys": {
                      "type": "Link",
                      "linkType": "ContentType",
                      "id": "propertyFeature"
                  }
              },
              "locale": "en-US"
          },
          "fields": {
              "label": "Easy Access",
              "value": "easy-access"
          }
      },
      {
          "metadata": {
              "tags": []
          },
          "sys": {
              "space": {
                  "sys": {
                      "type": "Link",
                      "linkType": "Space",
                      "id": "61geg9wy6l6n"
                  }
              },
              "id": "3AafHpjtLRha4VC8l121R9",
              "type": "Entry",
              "createdAt": "2020-08-07T02:15:26.589Z",
              "updatedAt": "2020-08-07T02:15:26.589Z",
              "environment": {
                  "sys": {
                      "id": "master",
                      "type": "Link",
                      "linkType": "Environment"
                  }
              },
              "revision": 1,
              "contentType": {
                  "sys": {
                      "type": "Link",
                      "linkType": "ContentType",
                      "id": "propertyFeature"
                  }
              },
              "locale": "en-US"
          },
          "fields": {
              "label": "Cul-de-sac",
              "value": "cul-de-sac"
          }
      },
      {
          "metadata": {
              "tags": []
          },
          "sys": {
              "space": {
                  "sys": {
                      "type": "Link",
                      "linkType": "Space",
                      "id": "61geg9wy6l6n"
                  }
              },
              "id": "27P1gCtalFK5007bCw631J",
              "type": "Entry",
              "createdAt": "2020-08-07T02:14:54.527Z",
              "updatedAt": "2020-08-07T02:14:54.527Z",
              "environment": {
                  "sys": {
                      "id": "master",
                      "type": "Link",
                      "linkType": "Environment"
                  }
              },
              "revision": 1,
              "contentType": {
                  "sys": {
                      "type": "Link",
                      "linkType": "ContentType",
                      "id": "propertyFeature"
                  }
              },
              "locale": "en-US"
          },
          "fields": {
              "label": "Central Location",
              "value": "central-location"
          }
      },
      {
          "metadata": {
              "tags": []
          },
          "sys": {
              "space": {
                  "sys": {
                      "type": "Link",
                      "linkType": "Space",
                      "id": "61geg9wy6l6n"
                  }
              },
              "id": "7zP8Pj5MjDsJwi3FOdoSdY",
              "type": "Entry",
              "createdAt": "2020-08-07T02:14:24.719Z",
              "updatedAt": "2020-08-07T02:14:24.719Z",
              "environment": {
                  "sys": {
                      "id": "master",
                      "type": "Link",
                      "linkType": "Environment"
                  }
              },
              "revision": 1,
              "contentType": {
                  "sys": {
                      "type": "Link",
                      "linkType": "ContentType",
                      "id": "propertyFeature"
                  }
              },
              "locale": "en-US"
          },
          "fields": {
              "label": "Beach Front",
              "value": "beach-front"
          }
      },
      {
          "metadata": {
              "tags": []
          },
          "sys": {
              "space": {
                  "sys": {
                      "type": "Link",
                      "linkType": "Space",
                      "id": "61geg9wy6l6n"
                  }
              },
              "id": "2WIhHYXwInecUvkH3CrsLq",
              "type": "Entry",
              "createdAt": "2020-08-07T02:14:03.032Z",
              "updatedAt": "2020-08-07T02:14:03.032Z",
              "environment": {
                  "sys": {
                      "id": "master",
                      "type": "Link",
                      "linkType": "Environment"
                  }
              },
              "revision": 1,
              "contentType": {
                  "sys": {
                      "type": "Link",
                      "linkType": "ContentType",
                      "id": "propertyFeature"
                  }
              },
              "locale": "en-US"
          },
          "fields": {
              "label": "Acreage",
              "value": "acreage"
          }
      }
     ];
     const features = items.map((type) => type.fields);
      dispatch(slice.actions.getPropertyFeaturesSuccess(features));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNavContent() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const res = await contentfulClient.getEntries({ content_type: 'navigation', order: 'fields.order' });
      const res = {
        "sys": {
            "type": "Array"
        },
        "total": 4,
        "skip": 0,
        "limit": 100,
        "items": [
            {
                "metadata": {
                    "tags": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "61geg9wy6l6n"
                        }
                    },
                    "id": "6l9YFreJUv6hleUKzJWAKJ",
                    "type": "Entry",
                    "createdAt": "2020-04-28T14:09:36.182Z",
                    "updatedAt": "2020-06-02T12:39:18.689Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "revision": 4,
                    "contentType": {
                        "sys": {
                            "type": "Link",
                            "linkType": "ContentType",
                            "id": "navigation"
                        }
                    },
                    "locale": "en-US"
                },
                "fields": {
                    "id": "homes-for-sale",
                    "icon": "iconsminds-home",
                    "label": "menu.homes-for-sale",
                    "to": "/homes-for-sale",
                    "subNav": [
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "57bOUmor65MAMLvWjhfrWe",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:12:51.088Z",
                                "updatedAt": "2020-04-28T14:13:24.619Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 2,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "houses-for-sale",
                                "label": "Homes For Sale",
                                "to": "/homes/for-sale/jamaica"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "hl7R0ScLimbLFiBSY499m",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:14:40.591Z",
                                "updatedAt": "2020-05-06T16:45:55.235Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 3,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "apartments-for-sale",
                                "label": "Apartments for sale",
                                "to": "/homes/for-sale/jamaica/apartment"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "2WUhIqLxke9I6YrulJRMyQ",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:16:09.619Z",
                                "updatedAt": "2020-09-07T15:33:25.543Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 2,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "beach-front-homes-for-sale",
                                "label": "Beach front homes for sale",
                                "to": "/homes/for-sale/jamaica?features=beach-front"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "4gOqbs5pSoQYL4l7iKCrJ1",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:16:43.589Z",
                                "updatedAt": "2020-04-28T14:16:43.589Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "land-for-sale",
                                "label": "Land for sale",
                                "to": "/homes/for-sale/jamaica/land"
                            }
                        }
                    ],
                    "regions": [
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "4bM0qbxtUSRMDzLmWqFWmx",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:26:35.304Z",
                                "updatedAt": "2020-04-28T14:26:35.304Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "houses-for-sale-montego-bay",
                                "label": "Montego Bay",
                                "to": "/homes/for-sale/saint-james/montego-bay"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "7t1aAoynCu22mbslOex1sZ",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:27:47.461Z",
                                "updatedAt": "2020-04-28T14:27:47.461Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-for-sale-negril",
                                "label": "Negril",
                                "to": "/homes/for-sale/jamaica/westmoreland/negril"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "6vMhjmDt9DmI6iVI5VXiex",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:31:51.137Z",
                                "updatedAt": "2020-04-28T14:31:51.137Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "houses-for-sale-clarendon",
                                "label": "Clarendon",
                                "to": "/homes/for-sale/jamaica/clarendon"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "1x3oLfIUoSMtRI02tytcm5",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:32:43.160Z",
                                "updatedAt": "2020-04-28T14:32:43.160Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "houses-for-sale-hanover",
                                "label": "Hanover",
                                "to": "/homes/for-sale/jamaica/hanover"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "43wGFkSnyrbDhnKqgEspqk",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:33:55.552Z",
                                "updatedAt": "2020-04-28T14:33:55.552Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "houses-for-sale-manchester",
                                "label": "Manchester",
                                "to": "/homes/for-sale/jamaica/manchester"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "6qRLD5OwBRYDmbX18M7kM5",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:34:28.447Z",
                                "updatedAt": "2020-04-28T14:34:28.447Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "houses-for-sale-portland",
                                "label": "Portland",
                                "to": "/homes/for-sale/jamaica/portland"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "7EdRe9d9FVgBEE5LJX2m9h",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:36:47.068Z",
                                "updatedAt": "2020-04-28T14:36:47.068Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-for-sale-saint-andrew",
                                "label": "St. Andrew",
                                "to": "/homes/for-sale/jamaica/saint-andrew"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "3yGWFqU1wubqKQv16EWamQ",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:41:12.067Z",
                                "updatedAt": "2020-04-28T14:41:12.067Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-for-sale-saint-james",
                                "label": "St. James",
                                "to": "/homes/for-sale/jamaica/saint-james"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "3nOxlRXxlBNNBEKMPQ6g8A",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:42:47.503Z",
                                "updatedAt": "2020-04-28T14:42:47.503Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-for-sale-saint-mary",
                                "label": "St. Mary",
                                "to": "/homes/for-sale/jamaica/saint-mary"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "14UFmdqjrkWd3YIlYjde48",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:43:35.820Z",
                                "updatedAt": "2020-04-28T14:43:35.820Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-for-sale-saint-thomas",
                                "label": "St. Thomas",
                                "to": "/homes/for-sale/jamaica/saint-thomas"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "1jSCNkazISFkRspICLVyim",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:44:30.230Z",
                                "updatedAt": "2020-04-28T14:44:30.230Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-for-sale-trelawny",
                                "label": "Trelawny",
                                "to": "/homes/for-sale/jamaica/trelawny"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "OiUwm4wd9Gefic3dGhQjE",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:45:30.779Z",
                                "updatedAt": "2020-04-28T14:45:30.779Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-for-sale-westmoreland",
                                "label": "Westmoreland",
                                "to": "/homes/for-sale/jamaica/westmoreland"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "69FFxah5wSfUQtzLdD4EsY",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:23:42.351Z",
                                "updatedAt": "2020-04-28T14:45:45.601Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 2,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "houses-for-sale-kingston",
                                "label": "Kingston",
                                "to": "/homes/for-sale/jamaica/kingston"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "5qsmNklB9TFSwAI3yCUP65",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:25:13.737Z",
                                "updatedAt": "2020-04-28T14:46:04.007Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 2,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "houses-for-sale-ocho-rios",
                                "label": "Ocho Rios",
                                "to": "/homes/for-sale/jamaica/saint-ann/ocho-rios"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "5O1ibkr0kA5ee1gVEdy7I6",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:39:27.843Z",
                                "updatedAt": "2020-04-28T14:47:26.800Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 2,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-for-sale-saint-elizabeth",
                                "label": "St. Elizabeth",
                                "to": "/homes/for-sale/jamaica/saint-elizabeth"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "z1Rp9QKjtNndkFZPIMK5r",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:38:31.497Z",
                                "updatedAt": "2020-04-28T14:47:44.320Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 2,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-for-sale-saint-catherine",
                                "label": "St. Catherine",
                                "to": "/homes/for-sale/jamaica/saint-catherine"
                            }
                        }
                    ],
                    "order": 1
                }
            },
            {
                "metadata": {
                    "tags": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "61geg9wy6l6n"
                        }
                    },
                    "id": "4kMt59xbo6VocqZ11sLkJr",
                    "type": "Entry",
                    "createdAt": "2020-04-28T14:10:20.793Z",
                    "updatedAt": "2020-06-02T12:39:37.193Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "revision": 4,
                    "contentType": {
                        "sys": {
                            "type": "Link",
                            "linkType": "ContentType",
                            "id": "navigation"
                        }
                    },
                    "locale": "en-US"
                },
                "fields": {
                    "id": "homes-for-rent",
                    "icon": "iconsminds-building",
                    "label": "menu.homes-for-rent",
                    "to": "/homes-for-rent",
                    "subNav": [
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "1ZYrKCu6FPp54AMXaNlyg0",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:19:22.802Z",
                                "updatedAt": "2020-04-28T14:19:22.802Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-for-rent",
                                "label": "Homes for rent",
                                "to": "/homes/for-rent/jamaica"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "32OhIIn4PFcyAG4vmrX0an",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:20:25.959Z",
                                "updatedAt": "2020-05-06T16:45:21.375Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 2,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "apartments-for-rent",
                                "label": "Apartments for rent",
                                "to": "/homes/for-rent/jamaica/apartment"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "4eJeIzXUp9NC8lRCd4Nepn",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:20:44.807Z",
                                "updatedAt": "2020-09-07T15:33:42.266Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 2,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "beach-front-homes-for-rent",
                                "label": "Beach front homes for rent",
                                "to": "/homes/for-rent/jamaica?features=beach-front"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "3WyyQemvNG3pua4BLEZ8X9",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:21:04.937Z",
                                "updatedAt": "2020-04-28T14:21:04.937Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "land-for-rent",
                                "label": "Land for rent",
                                "to": "/homes/for-rent/jamaica/land"
                            }
                        }
                    ],
                    "regions": [
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "6yBp9oQXmHzEEDUq0OWOqC",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:53:43.550Z",
                                "updatedAt": "2020-04-28T14:53:43.550Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-for-rent-negril",
                                "label": "Negril",
                                "to": "/homes/for-rent/jamaica/westmoreland/negril"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "4c1ZdwFGQfcGnY1vrAx1Zy",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:57:31.576Z",
                                "updatedAt": "2020-04-28T14:57:31.576Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "houses-rent-sale-hanover",
                                "label": "Hanover",
                                "to": "/homes/for-rent/jamaica/hanover"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "2J02ln3DIfGu7llTjH0tFz",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:57:09.847Z",
                                "updatedAt": "2020-04-28T14:57:09.847Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-for-rent-saint-catherine",
                                "label": "St. Catherine",
                                "to": "/homes/for-rent/jamaica/saint-catherine"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "4XhzwAPGLVTdURwJTHWDqw",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:56:56.941Z",
                                "updatedAt": "2020-04-28T14:56:56.941Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "houses-for-rent-clarendon",
                                "label": "Clarendon",
                                "to": "/homes/for-rent/jamaica/clarendon"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "2Jh5Ys4Y8ZO3ImYQ9vQasc",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:56:42.418Z",
                                "updatedAt": "2020-04-28T14:56:42.418Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "houses-for-rent-montego-bay",
                                "label": "Montego Bay",
                                "to": "/homes/for-rent/saint-james/montego-bay"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "32Cf0DGy3rN5lIQn0GwLti",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:56:15.107Z",
                                "updatedAt": "2020-04-28T14:56:15.107Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "houses-for-rent-portland",
                                "label": "Portland",
                                "to": "/homes/for-rent/jamaica/portland"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "oQsCH4JRLGDykAKcSZatn",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:55:58.897Z",
                                "updatedAt": "2020-04-28T14:55:58.897Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-for-rent-saint-andrew",
                                "label": "St. Andrew",
                                "to": "/homes/for-rent/jamaica/saint-andrew"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "5z7sN42XByGu519CRPIzDp",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:55:45.739Z",
                                "updatedAt": "2020-04-28T14:55:45.739Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-for-rent-saint-james",
                                "label": "St. James",
                                "to": "/homes/for-rent/jamaica/saint-james"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "5PG5eErPVsoI00J7Nj5PVq",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:55:29.864Z",
                                "updatedAt": "2020-04-28T14:55:29.864Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-for-rent-saint-thomas",
                                "label": "St. Thomas",
                                "to": "/homes/for-rent/jamaica/saint-thomas"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "7vdmsSN8N790QsGs0Z3DzJ",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:55:17.083Z",
                                "updatedAt": "2020-04-28T14:55:17.083Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-for-rent-saint-mary",
                                "label": "St. Mary",
                                "to": "/homes/for-rent/jamaica/saint-mary"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "7AcabpOoKppd7dD0Dr1wmC",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:55:04.377Z",
                                "updatedAt": "2020-04-28T14:55:04.377Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "houses-for-rent-ocho-rios",
                                "label": "Ocho Rios",
                                "to": "/homes/for-rent/jamaica/saint-ann/ocho-rios"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "5Gsk3R1vVrYez9ZA58iW6m",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:54:49.976Z",
                                "updatedAt": "2020-04-28T14:54:49.976Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "houses-for-rent-manchester",
                                "label": "Manchester",
                                "to": "/homes/for-rent/jamaica/manchester"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "5ve1MPyOn5IJUfUSt0lwNh",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:54:37.741Z",
                                "updatedAt": "2020-04-28T14:54:37.741Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-for-rent-westmoreland",
                                "label": "Westmoreland",
                                "to": "/homes/for-rent/jamaica/westmoreland"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "hd0a4dXFPkRlYkWGjnnj1",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:54:24.014Z",
                                "updatedAt": "2020-04-28T14:54:24.014Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-for-rent-trelawny",
                                "label": "Trelawny",
                                "to": "/homes/for-rent/jamaica/trelawny"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "4IOcpUPXSsdaRbGIJ7Llof",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:54:11.185Z",
                                "updatedAt": "2020-04-28T14:54:11.185Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "houses-for-rent-kingston",
                                "label": "Kingston",
                                "to": "/homes/for-rent/jamaica/kingston"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "4w9JPppPNlVlvNQ7IquHNk",
                                "type": "Entry",
                                "createdAt": "2020-04-28T14:53:57.269Z",
                                "updatedAt": "2020-04-28T14:53:57.269Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-for-rent-saint-elizabeth",
                                "label": "St. Elizabeth",
                                "to": "/homes/for-rent/jamaica/saint-elizabeth"
                            }
                        }
                    ],
                    "order": 2
                }
            },
            {
                "metadata": {
                    "tags": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "61geg9wy6l6n"
                        }
                    },
                    "id": "5elEkzNUognj4jHfTJ1VdV",
                    "type": "Entry",
                    "createdAt": "2020-04-28T14:11:09.390Z",
                    "updatedAt": "2020-06-02T12:39:53.776Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "revision": 3,
                    "contentType": {
                        "sys": {
                            "type": "Link",
                            "linkType": "ContentType",
                            "id": "navigation"
                        }
                    },
                    "locale": "en-US"
                },
                "fields": {
                    "id": "commercial-properties",
                    "icon": "iconsminds-business-man",
                    "label": "menu.commercial-properties",
                    "to": "/commercial-properties",
                    "regions": [
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "1vyVuiJhPVetSghws9CWF2",
                                "type": "Entry",
                                "createdAt": "2020-04-30T01:59:14.898Z",
                                "updatedAt": "2020-04-30T01:59:14.898Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "houses-commercial-ocho-rios",
                                "label": "Ocho Rios",
                                "to": "/homes/commercial/jamaica/saint-ann/ocho-rios"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "10UOmPSsEG8pghuuvs6lZn",
                                "type": "Entry",
                                "createdAt": "2020-04-30T01:58:39.131Z",
                                "updatedAt": "2020-04-30T01:58:39.131Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-commercial-saint-elizabeth",
                                "label": "St. Elizabeth",
                                "to": "/homes/commercial/jamaica/saint-elizabeth"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "27986QONc7LZOfQdVFnnEF",
                                "type": "Entry",
                                "createdAt": "2020-04-30T01:58:08.845Z",
                                "updatedAt": "2020-04-30T01:58:08.845Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-commercial-negril",
                                "label": "Negril",
                                "to": "/homes/commercial/jamaica/westmoreland/negril"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "4ui03XgNcp0xADaEFHyYnV",
                                "type": "Entry",
                                "createdAt": "2020-04-30T01:57:40.751Z",
                                "updatedAt": "2020-04-30T01:57:40.751Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "houses-commercial-manchester",
                                "label": "Manchester",
                                "to": "/homes/commercial/jamaica/manchester"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "2eue2L7o8YYwkvTWOqabnx",
                                "type": "Entry",
                                "createdAt": "2020-04-30T01:57:16.023Z",
                                "updatedAt": "2020-04-30T01:57:16.023Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-commercial-trelawny",
                                "label": "Trelawny",
                                "to": "/homes/commercial/jamaica/trelawny"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "58CO0bTLn7lPyanIeFzWas",
                                "type": "Entry",
                                "createdAt": "2020-04-30T01:56:44.019Z",
                                "updatedAt": "2020-04-30T01:56:44.019Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-commercial-saint-mary",
                                "label": "St. Mary",
                                "to": "/homes/commercial/jamaica/saint-mary"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "6XWk93c16gcJMlqkl6Sf9Y",
                                "type": "Entry",
                                "createdAt": "2020-04-30T01:56:14.988Z",
                                "updatedAt": "2020-04-30T01:56:14.988Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-commercial-westmoreland",
                                "label": "Westmoreland",
                                "to": "/homes/commercial/jamaica/westmoreland"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "kmNfAvC4xl67TT1i4baeJ",
                                "type": "Entry",
                                "createdAt": "2020-04-30T01:55:48.933Z",
                                "updatedAt": "2020-04-30T01:55:48.933Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-commercial-saint-thomas",
                                "label": "St. Thomas",
                                "to": "/homes/commercial/jamaica/saint-thomas"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "2khy6D80Ysqk2IeTow3A5N",
                                "type": "Entry",
                                "createdAt": "2020-04-30T01:55:30.217Z",
                                "updatedAt": "2020-04-30T01:55:30.217Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "houses-commercial-hanover",
                                "label": "Hanover",
                                "to": "/homes/commercial/jamaica/hanover"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "5Koybufj6ckADqKgSBiujU",
                                "type": "Entry",
                                "createdAt": "2020-04-30T01:55:12.415Z",
                                "updatedAt": "2020-04-30T01:55:12.415Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-commercial-saint-andrew",
                                "label": "St. Andrew",
                                "to": "/homes/commercial/jamaica/saint-andrew"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "3ZI9e63rJUmu4kcEe1hvp5",
                                "type": "Entry",
                                "createdAt": "2020-04-30T01:54:52.590Z",
                                "updatedAt": "2020-04-30T01:54:52.590Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "houses-commercial-kingston",
                                "label": "Kingston",
                                "to": "/homes/commercial/jamaica/kingston"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "5Mln5csfJbOCJVSjODcqGv",
                                "type": "Entry",
                                "createdAt": "2020-04-30T01:54:34.674Z",
                                "updatedAt": "2020-04-30T01:54:34.674Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "homes-commercial-saint-james",
                                "label": "St. James",
                                "to": "/homes/commercial/jamaica/saint-james"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "UgcQUXXe1T2PoILSwG0KK",
                                "type": "Entry",
                                "createdAt": "2020-04-30T01:53:50.519Z",
                                "updatedAt": "2020-04-30T01:53:59.796Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 2,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "houses-commercial-montego-bay",
                                "label": "Montego Bay",
                                "to": "/homes/commercial/saint-james/montego-bay"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "5Nw1iazbVw7ZzFDQ4e64rI",
                                "type": "Entry",
                                "createdAt": "2020-04-30T01:52:47.282Z",
                                "updatedAt": "2020-04-30T01:53:19.439Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 2,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "houses-commercial-clarendon",
                                "label": "Clarendon",
                                "to": "/homes/commercial/jamaica/clarendon"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "hb4Kxd12ZO0O3MF4XZYCF",
                                "type": "Entry",
                                "createdAt": "2020-04-30T01:52:25.859Z",
                                "updatedAt": "2020-04-30T01:53:08.457Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 2,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "houses-commercial-saint-catherine",
                                "label": "St. Catherine",
                                "to": "/homes/commercial/jamaica/saint-catherine"
                            }
                        },
                        {
                            "metadata": {
                                "tags": []
                            },
                            "sys": {
                                "space": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "Space",
                                        "id": "61geg9wy6l6n"
                                    }
                                },
                                "id": "1UZstFm0DGUL7c2FGGEbu7",
                                "type": "Entry",
                                "createdAt": "2020-04-30T01:54:16.400Z",
                                "updatedAt": "2020-04-30T01:54:16.400Z",
                                "environment": {
                                    "sys": {
                                        "id": "master",
                                        "type": "Link",
                                        "linkType": "Environment"
                                    }
                                },
                                "revision": 1,
                                "contentType": {
                                    "sys": {
                                        "type": "Link",
                                        "linkType": "ContentType",
                                        "id": "navigationLinks"
                                    }
                                },
                                "locale": "en-US"
                            },
                            "fields": {
                                "id": "houses-commercial-portland",
                                "label": "Portland",
                                "to": "/homes/commercial/jamaica/portland"
                            }
                        }
                    ],
                    "order": 3
                }
            },
            {
                "metadata": {
                    "tags": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "61geg9wy6l6n"
                        }
                    },
                    "id": "7Dj9yc2zvqs8g9V0HuOD8A",
                    "type": "Entry",
                    "createdAt": "2020-04-28T14:11:46.132Z",
                    "updatedAt": "2020-06-02T12:40:07.505Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "revision": 4,
                    "contentType": {
                        "sys": {
                            "type": "Link",
                            "linkType": "ContentType",
                            "id": "navigation"
                        }
                    },
                    "locale": "en-US"
                },
                "fields": {
                    "id": "real-estate-guide",
                    "icon": "iconsminds-monitor-analytics",
                    "label": "menu.real-estate-guide",
                    "to": "/real-estate/guide/all",
                    "order": 4
                }
            }
        ],
        "includes": {
            "Entry": [
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "10UOmPSsEG8pghuuvs6lZn",
                        "type": "Entry",
                        "createdAt": "2020-04-30T01:58:39.131Z",
                        "updatedAt": "2020-04-30T01:58:39.131Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-commercial-saint-elizabeth",
                        "label": "St. Elizabeth",
                        "to": "/homes/commercial/jamaica/saint-elizabeth"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "14UFmdqjrkWd3YIlYjde48",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:43:35.820Z",
                        "updatedAt": "2020-04-28T14:43:35.820Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-for-sale-saint-thomas",
                        "label": "St. Thomas",
                        "to": "/homes/for-sale/jamaica/saint-thomas"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "1UZstFm0DGUL7c2FGGEbu7",
                        "type": "Entry",
                        "createdAt": "2020-04-30T01:54:16.400Z",
                        "updatedAt": "2020-04-30T01:54:16.400Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "houses-commercial-portland",
                        "label": "Portland",
                        "to": "/homes/commercial/jamaica/portland"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "1ZYrKCu6FPp54AMXaNlyg0",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:19:22.802Z",
                        "updatedAt": "2020-04-28T14:19:22.802Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-for-rent",
                        "label": "Homes for rent",
                        "to": "/homes/for-rent/jamaica"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "1jSCNkazISFkRspICLVyim",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:44:30.230Z",
                        "updatedAt": "2020-04-28T14:44:30.230Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-for-sale-trelawny",
                        "label": "Trelawny",
                        "to": "/homes/for-sale/jamaica/trelawny"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "1vyVuiJhPVetSghws9CWF2",
                        "type": "Entry",
                        "createdAt": "2020-04-30T01:59:14.898Z",
                        "updatedAt": "2020-04-30T01:59:14.898Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "houses-commercial-ocho-rios",
                        "label": "Ocho Rios",
                        "to": "/homes/commercial/jamaica/saint-ann/ocho-rios"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "1x3oLfIUoSMtRI02tytcm5",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:32:43.160Z",
                        "updatedAt": "2020-04-28T14:32:43.160Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "houses-for-sale-hanover",
                        "label": "Hanover",
                        "to": "/homes/for-sale/jamaica/hanover"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "27986QONc7LZOfQdVFnnEF",
                        "type": "Entry",
                        "createdAt": "2020-04-30T01:58:08.845Z",
                        "updatedAt": "2020-04-30T01:58:08.845Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-commercial-negril",
                        "label": "Negril",
                        "to": "/homes/commercial/jamaica/westmoreland/negril"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "2J02ln3DIfGu7llTjH0tFz",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:57:09.847Z",
                        "updatedAt": "2020-04-28T14:57:09.847Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-for-rent-saint-catherine",
                        "label": "St. Catherine",
                        "to": "/homes/for-rent/jamaica/saint-catherine"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "2Jh5Ys4Y8ZO3ImYQ9vQasc",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:56:42.418Z",
                        "updatedAt": "2020-04-28T14:56:42.418Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "houses-for-rent-montego-bay",
                        "label": "Montego Bay",
                        "to": "/homes/for-rent/saint-james/montego-bay"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "2WUhIqLxke9I6YrulJRMyQ",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:16:09.619Z",
                        "updatedAt": "2020-09-07T15:33:25.543Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 2,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "beach-front-homes-for-sale",
                        "label": "Beach front homes for sale",
                        "to": "/homes/for-sale/jamaica?features=beach-front"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "2eue2L7o8YYwkvTWOqabnx",
                        "type": "Entry",
                        "createdAt": "2020-04-30T01:57:16.023Z",
                        "updatedAt": "2020-04-30T01:57:16.023Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-commercial-trelawny",
                        "label": "Trelawny",
                        "to": "/homes/commercial/jamaica/trelawny"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "2khy6D80Ysqk2IeTow3A5N",
                        "type": "Entry",
                        "createdAt": "2020-04-30T01:55:30.217Z",
                        "updatedAt": "2020-04-30T01:55:30.217Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "houses-commercial-hanover",
                        "label": "Hanover",
                        "to": "/homes/commercial/jamaica/hanover"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "32Cf0DGy3rN5lIQn0GwLti",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:56:15.107Z",
                        "updatedAt": "2020-04-28T14:56:15.107Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "houses-for-rent-portland",
                        "label": "Portland",
                        "to": "/homes/for-rent/jamaica/portland"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "32OhIIn4PFcyAG4vmrX0an",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:20:25.959Z",
                        "updatedAt": "2020-05-06T16:45:21.375Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 2,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "apartments-for-rent",
                        "label": "Apartments for rent",
                        "to": "/homes/for-rent/jamaica/apartment"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "3WyyQemvNG3pua4BLEZ8X9",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:21:04.937Z",
                        "updatedAt": "2020-04-28T14:21:04.937Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "land-for-rent",
                        "label": "Land for rent",
                        "to": "/homes/for-rent/jamaica/land"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "3ZI9e63rJUmu4kcEe1hvp5",
                        "type": "Entry",
                        "createdAt": "2020-04-30T01:54:52.590Z",
                        "updatedAt": "2020-04-30T01:54:52.590Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "houses-commercial-kingston",
                        "label": "Kingston",
                        "to": "/homes/commercial/jamaica/kingston"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "3nOxlRXxlBNNBEKMPQ6g8A",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:42:47.503Z",
                        "updatedAt": "2020-04-28T14:42:47.503Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-for-sale-saint-mary",
                        "label": "St. Mary",
                        "to": "/homes/for-sale/jamaica/saint-mary"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "3yGWFqU1wubqKQv16EWamQ",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:41:12.067Z",
                        "updatedAt": "2020-04-28T14:41:12.067Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-for-sale-saint-james",
                        "label": "St. James",
                        "to": "/homes/for-sale/jamaica/saint-james"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "43wGFkSnyrbDhnKqgEspqk",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:33:55.552Z",
                        "updatedAt": "2020-04-28T14:33:55.552Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "houses-for-sale-manchester",
                        "label": "Manchester",
                        "to": "/homes/for-sale/jamaica/manchester"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "4IOcpUPXSsdaRbGIJ7Llof",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:54:11.185Z",
                        "updatedAt": "2020-04-28T14:54:11.185Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "houses-for-rent-kingston",
                        "label": "Kingston",
                        "to": "/homes/for-rent/jamaica/kingston"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "4XhzwAPGLVTdURwJTHWDqw",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:56:56.941Z",
                        "updatedAt": "2020-04-28T14:56:56.941Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "houses-for-rent-clarendon",
                        "label": "Clarendon",
                        "to": "/homes/for-rent/jamaica/clarendon"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "4bM0qbxtUSRMDzLmWqFWmx",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:26:35.304Z",
                        "updatedAt": "2020-04-28T14:26:35.304Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "houses-for-sale-montego-bay",
                        "label": "Montego Bay",
                        "to": "/homes/for-sale/saint-james/montego-bay"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "4c1ZdwFGQfcGnY1vrAx1Zy",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:57:31.576Z",
                        "updatedAt": "2020-04-28T14:57:31.576Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "houses-rent-sale-hanover",
                        "label": "Hanover",
                        "to": "/homes/for-rent/jamaica/hanover"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "4eJeIzXUp9NC8lRCd4Nepn",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:20:44.807Z",
                        "updatedAt": "2020-09-07T15:33:42.266Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 2,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "beach-front-homes-for-rent",
                        "label": "Beach front homes for rent",
                        "to": "/homes/for-rent/jamaica?features=beach-front"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "4gOqbs5pSoQYL4l7iKCrJ1",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:16:43.589Z",
                        "updatedAt": "2020-04-28T14:16:43.589Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "land-for-sale",
                        "label": "Land for sale",
                        "to": "/homes/for-sale/jamaica/land"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "4ui03XgNcp0xADaEFHyYnV",
                        "type": "Entry",
                        "createdAt": "2020-04-30T01:57:40.751Z",
                        "updatedAt": "2020-04-30T01:57:40.751Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "houses-commercial-manchester",
                        "label": "Manchester",
                        "to": "/homes/commercial/jamaica/manchester"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "4w9JPppPNlVlvNQ7IquHNk",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:53:57.269Z",
                        "updatedAt": "2020-04-28T14:53:57.269Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-for-rent-saint-elizabeth",
                        "label": "St. Elizabeth",
                        "to": "/homes/for-rent/jamaica/saint-elizabeth"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "57bOUmor65MAMLvWjhfrWe",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:12:51.088Z",
                        "updatedAt": "2020-04-28T14:13:24.619Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 2,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "houses-for-sale",
                        "label": "Homes For Sale",
                        "to": "/homes/for-sale/jamaica"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "58CO0bTLn7lPyanIeFzWas",
                        "type": "Entry",
                        "createdAt": "2020-04-30T01:56:44.019Z",
                        "updatedAt": "2020-04-30T01:56:44.019Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-commercial-saint-mary",
                        "label": "St. Mary",
                        "to": "/homes/commercial/jamaica/saint-mary"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "5Gsk3R1vVrYez9ZA58iW6m",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:54:49.976Z",
                        "updatedAt": "2020-04-28T14:54:49.976Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "houses-for-rent-manchester",
                        "label": "Manchester",
                        "to": "/homes/for-rent/jamaica/manchester"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "5Koybufj6ckADqKgSBiujU",
                        "type": "Entry",
                        "createdAt": "2020-04-30T01:55:12.415Z",
                        "updatedAt": "2020-04-30T01:55:12.415Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-commercial-saint-andrew",
                        "label": "St. Andrew",
                        "to": "/homes/commercial/jamaica/saint-andrew"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "5Mln5csfJbOCJVSjODcqGv",
                        "type": "Entry",
                        "createdAt": "2020-04-30T01:54:34.674Z",
                        "updatedAt": "2020-04-30T01:54:34.674Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-commercial-saint-james",
                        "label": "St. James",
                        "to": "/homes/commercial/jamaica/saint-james"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "5Nw1iazbVw7ZzFDQ4e64rI",
                        "type": "Entry",
                        "createdAt": "2020-04-30T01:52:47.282Z",
                        "updatedAt": "2020-04-30T01:53:19.439Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 2,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "houses-commercial-clarendon",
                        "label": "Clarendon",
                        "to": "/homes/commercial/jamaica/clarendon"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "5O1ibkr0kA5ee1gVEdy7I6",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:39:27.843Z",
                        "updatedAt": "2020-04-28T14:47:26.800Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 2,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-for-sale-saint-elizabeth",
                        "label": "St. Elizabeth",
                        "to": "/homes/for-sale/jamaica/saint-elizabeth"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "5PG5eErPVsoI00J7Nj5PVq",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:55:29.864Z",
                        "updatedAt": "2020-04-28T14:55:29.864Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-for-rent-saint-thomas",
                        "label": "St. Thomas",
                        "to": "/homes/for-rent/jamaica/saint-thomas"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "5qsmNklB9TFSwAI3yCUP65",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:25:13.737Z",
                        "updatedAt": "2020-04-28T14:46:04.007Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 2,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "houses-for-sale-ocho-rios",
                        "label": "Ocho Rios",
                        "to": "/homes/for-sale/jamaica/saint-ann/ocho-rios"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "5ve1MPyOn5IJUfUSt0lwNh",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:54:37.741Z",
                        "updatedAt": "2020-04-28T14:54:37.741Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-for-rent-westmoreland",
                        "label": "Westmoreland",
                        "to": "/homes/for-rent/jamaica/westmoreland"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "5z7sN42XByGu519CRPIzDp",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:55:45.739Z",
                        "updatedAt": "2020-04-28T14:55:45.739Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-for-rent-saint-james",
                        "label": "St. James",
                        "to": "/homes/for-rent/jamaica/saint-james"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "69FFxah5wSfUQtzLdD4EsY",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:23:42.351Z",
                        "updatedAt": "2020-04-28T14:45:45.601Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 2,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "houses-for-sale-kingston",
                        "label": "Kingston",
                        "to": "/homes/for-sale/jamaica/kingston"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "6XWk93c16gcJMlqkl6Sf9Y",
                        "type": "Entry",
                        "createdAt": "2020-04-30T01:56:14.988Z",
                        "updatedAt": "2020-04-30T01:56:14.988Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-commercial-westmoreland",
                        "label": "Westmoreland",
                        "to": "/homes/commercial/jamaica/westmoreland"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "6qRLD5OwBRYDmbX18M7kM5",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:34:28.447Z",
                        "updatedAt": "2020-04-28T14:34:28.447Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "houses-for-sale-portland",
                        "label": "Portland",
                        "to": "/homes/for-sale/jamaica/portland"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "6vMhjmDt9DmI6iVI5VXiex",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:31:51.137Z",
                        "updatedAt": "2020-04-28T14:31:51.137Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "houses-for-sale-clarendon",
                        "label": "Clarendon",
                        "to": "/homes/for-sale/jamaica/clarendon"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "6yBp9oQXmHzEEDUq0OWOqC",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:53:43.550Z",
                        "updatedAt": "2020-04-28T14:53:43.550Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-for-rent-negril",
                        "label": "Negril",
                        "to": "/homes/for-rent/jamaica/westmoreland/negril"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "7AcabpOoKppd7dD0Dr1wmC",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:55:04.377Z",
                        "updatedAt": "2020-04-28T14:55:04.377Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "houses-for-rent-ocho-rios",
                        "label": "Ocho Rios",
                        "to": "/homes/for-rent/jamaica/saint-ann/ocho-rios"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "7EdRe9d9FVgBEE5LJX2m9h",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:36:47.068Z",
                        "updatedAt": "2020-04-28T14:36:47.068Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-for-sale-saint-andrew",
                        "label": "St. Andrew",
                        "to": "/homes/for-sale/jamaica/saint-andrew"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "7t1aAoynCu22mbslOex1sZ",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:27:47.461Z",
                        "updatedAt": "2020-04-28T14:27:47.461Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-for-sale-negril",
                        "label": "Negril",
                        "to": "/homes/for-sale/jamaica/westmoreland/negril"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "7vdmsSN8N790QsGs0Z3DzJ",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:55:17.083Z",
                        "updatedAt": "2020-04-28T14:55:17.083Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-for-rent-saint-mary",
                        "label": "St. Mary",
                        "to": "/homes/for-rent/jamaica/saint-mary"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "OiUwm4wd9Gefic3dGhQjE",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:45:30.779Z",
                        "updatedAt": "2020-04-28T14:45:30.779Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-for-sale-westmoreland",
                        "label": "Westmoreland",
                        "to": "/homes/for-sale/jamaica/westmoreland"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "UgcQUXXe1T2PoILSwG0KK",
                        "type": "Entry",
                        "createdAt": "2020-04-30T01:53:50.519Z",
                        "updatedAt": "2020-04-30T01:53:59.796Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 2,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "houses-commercial-montego-bay",
                        "label": "Montego Bay",
                        "to": "/homes/commercial/saint-james/montego-bay"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "hb4Kxd12ZO0O3MF4XZYCF",
                        "type": "Entry",
                        "createdAt": "2020-04-30T01:52:25.859Z",
                        "updatedAt": "2020-04-30T01:53:08.457Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 2,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "houses-commercial-saint-catherine",
                        "label": "St. Catherine",
                        "to": "/homes/commercial/jamaica/saint-catherine"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "hd0a4dXFPkRlYkWGjnnj1",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:54:24.014Z",
                        "updatedAt": "2020-04-28T14:54:24.014Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-for-rent-trelawny",
                        "label": "Trelawny",
                        "to": "/homes/for-rent/jamaica/trelawny"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "hl7R0ScLimbLFiBSY499m",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:14:40.591Z",
                        "updatedAt": "2020-05-06T16:45:55.235Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 3,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "apartments-for-sale",
                        "label": "Apartments for sale",
                        "to": "/homes/for-sale/jamaica/apartment"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "kmNfAvC4xl67TT1i4baeJ",
                        "type": "Entry",
                        "createdAt": "2020-04-30T01:55:48.933Z",
                        "updatedAt": "2020-04-30T01:55:48.933Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-commercial-saint-thomas",
                        "label": "St. Thomas",
                        "to": "/homes/commercial/jamaica/saint-thomas"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "oQsCH4JRLGDykAKcSZatn",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:55:58.897Z",
                        "updatedAt": "2020-04-28T14:55:58.897Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 1,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-for-rent-saint-andrew",
                        "label": "St. Andrew",
                        "to": "/homes/for-rent/jamaica/saint-andrew"
                    }
                },
                {
                    "metadata": {
                        "tags": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "61geg9wy6l6n"
                            }
                        },
                        "id": "z1Rp9QKjtNndkFZPIMK5r",
                        "type": "Entry",
                        "createdAt": "2020-04-28T14:38:31.497Z",
                        "updatedAt": "2020-04-28T14:47:44.320Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "revision": 2,
                        "contentType": {
                            "sys": {
                                "type": "Link",
                                "linkType": "ContentType",
                                "id": "navigationLinks"
                            }
                        },
                        "locale": "en-US"
                    },
                    "fields": {
                        "id": "homes-for-sale-saint-catherine",
                        "label": "St. Catherine",
                        "to": "/homes/for-sale/jamaica/saint-catherine"
                    }
                }
            ]
        }
      };
      dispatch(slice.actions.getNavContentSuccess(res));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFaqs() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
     const res = await  contentfulClient.getEntries({ content_type: 'faq' })
      dispatch(slice.actions.getFaqsSuccess(res));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
