import axios from 'axios';

const api = {
  
    get(api, params, auth, responseType) {

      return new Promise(async (resolve, reject) => {

        try {
          const args = {
            params: params,
          };

          if(responseType) {
              args['responseType'] = responseType;
          }

          const response = await axios.get(`${process.env.REACT_APP_REC_API}${api}`, args);

          resolve({
            data: response.data.response || response.data,
          });
        } catch (e) {
          reject(e);
        }
      });
    },
    getById(api, id, auth) {
      const args = {
      };

      return axios.get(`${process.env.REACT_APP_REC_API}${api}/${id}`, args);
    },
 	post(api, data, auth) {
        
    let headers = {};

    return axios.post(`${process.env.REACT_APP_REC_API}${api}`, data, {
        headers: headers
    });
  },
  delete(api, id, auth) {
    let headers = {};

    return axios.delete(`${process.env.REACT_APP_REC_API}${api}/${id}`, {
        headers: headers
    });
  },
  put(api, id, data, auth) {
    let headers = {};

    return axios.put(`${process.env.REACT_APP_REC_API}${api}/${id}`, data,  {
        headers: headers
    });
  },
  patch(api, id, data, auth) { 
    let headers = {};

    return axios.patch(`${process.env.REACT_APP_REC_API}${api}/${id}`, data,  {
        headers: headers
    });
  },
  upload(api, file, auth) {
    const formData = new FormData();
    formData.append("file", file);
    
    let headers = {
        'Content-Type': 'multipart/form-data'
    }

    return axios.post(`${process.env.REACT_APP_REC_API}${api}`, formData,  {
        headers: headers
    })
  }
    
}

export default api