/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const REGISTER_USER_SOCIAL = "REGISTER_USER_SOCIAL"
export const REGISTER_USER_SOCIAL_SUCCESS = "REGISTER_USER_SOCIAL_SUCCESS"
export const REGISTER_USER_SOCIAL_ERROR = "REGISTER_USER_SOCIAL_ERROR"

export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const SIGN_IN_WITH_REDIRECT = "SIGN_IN_WITH_REDIRECT"
export const SIGN_IN_WITH_REDIRECT_SUCCESS = "SIGN_IN_WITH_REDIRECT_SUCCESS"
export const SIGN_IN_WITH_REDIRECT_ERROR = "SIGN_IN_WITH_REDIRECT_ERROR"

export const REFRESH_USER = "REFRESH_USER"
export const REFRESH_USER_SUCCESS = "REFRESH_USER_SUCCESS"
export const REFRESH_USER_ERROR = "REFRESH_USER_ERROR"
export const CHECK_AUTH_STATE = "CHECK_AUTH_STATE"
export const CHECK_AUTH_STATE_ERROR = "CHECK_AUTH_STATE_ERROR"
export const CHECK_AUTH_STATE_SUCCESS = "CHECK_AUTH_STATE_SUCCESS"

export const ACCOUNT_CLEAR = "ACCOUNT_CLEAR"

export const USER_VIEW_PROPS = "USER_VIEW_PROPS"
export const USER_VIEW_PROPS_SUCCESS = "USER_VIEW_PROPS_SUCCESS"
export const USER_VIEW_PROPS_ERROR = "USER_VIEW_PROPS_ERROR"
export const USERS_UPDATE_USER = "USERS_UPDATE_USER"
export const USERS_UPDATE_USER_SUCCESS = "USERS_UPDATE_USER_SUCCESS"
export const USERS_UPDATE_USER_ERROR = "USERS_UPDATE_USER_ERROR"
export const USER_UPDATE_PROFILE_PIC = "USER_UPDATE_PROFILE_PIC"
export const USER_UPDATE_PROFILE_PIC_SUCCESS = "USER_UPDATE_PROFILE_PIC_SUCCESS"
export const USER_UPDATE_PROFILE_PIC_ERROR = "USER_UPDATE_PROFILE_PIC_ERROR"
export const USER_DELETE = "USER_DELETE"
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS"
export const USER_DELETE_ERROR = "USER_DELETE_ERROR"
export const USER_TOGGLE_SUBSCRIPTION = "USER_TOGGLE_SUBSCRIPTION"
export const USER_TOGGLE_SUBSCRIPTION_SUCCESS = "USER_TOGGLE_SUBSCRIPTION_SUCCESS"
export const USER_TOGGLE_SUBSCRIPTION_ERROR = "USER_TOGGLE_SUBSCRIPTION_ERROR"
export const USER_GET_BY_ID = "USER_GET_BY_ID"
export const USER_GET_BY_ID_SUCCESS = "USER_GET_BY_ID_SUCCESS"
export const USER_GET_BY_ID_ERROR = "USER_GET_BY_ID_ERROR"
export const USER_GET_ME = "USER_GET_ME"
export const USER_GET_ME_SUCCESS = "USER_GET_ME_SUCCESS"
export const USER_GET_ME_ERROR = "USER_GET_ME_ERROR"
export const USER_GET_PROS = "USER_GET_PROS"
export const USER_GET_PROS_SUCCESS = "USER_GET_PROS_SUCCESS"
export const USER_GET_PROS_ERROR = "USER_GET_PROS_ERROR"
export const USER_CLEAR = "USER_CLEAR"

/* PROPS*/
export const PROPS_GET_LIST = "PROPS_GET_LIST";
export const PROPS_GET_LIST_SUCCESS = "PROPS_GET_LIST_SUCCESS";
export const PROPS_GET_LIST_ERROR = "PROPS_GET_LIST_ERROR";
export const PROPS_GET_RELATED_LIST = "PROPS_GET_RELATED_LIST";
export const PROPS_GET_RELATED_LIST_SUCCESS = "PROPS_GET_RELATED_LIST_SUCCESS";
export const PROPS_GET_RELATED_LIST_ERROR = "PROPS_GET_RELATED_LIST_ERROR";
export const PROPS_GET_PROPERTY = "PROPS_GET_PROPERTY";
export const PROPS_GET_PROPERTY_SUCCESS = "PROPS_GET_PROPERTY_SUCCESS";
export const PROPS_GET_PROPERTY_ERROR = "PROPS_GET_PROPERTY_ERROR";
export const PROPS_ADD_ITEM = "PROPS_ADD_ITEM";
export const PROPS_ADD_ITEM_SUCCESS = "PROPS_ADD_ITEM_SUCCESS";
export const PROPS_ADD_ITEM_ERROR = "PROPS_ADD_ITEM_ERROR";
export const PROPS_GET_MAP_LIST = "PROPS_GET_MAP_LIST"
export const PROPS_GET_MAP_LIST_SUCCESS = "PROPS_GET_MAP_LIST_SUCCESS"
export const PROPS_GET_MAP_LIST_ERROR = "PROPS_GET_MAP_LIST_ERROR"
export const PROPS_GET_MAP_POINT = "PROPS_GET_MAP_POINT"
export const PROPS_GET_MAP_POINT_SUCCESS = "PROPS_GET_MAP_POINT_SUCCESS"
export const PROPS_GET_MAP_POINT_ERROR = "PROPS_GET_MAP_POINT_ERROR"
export const PROPS_GET_MAP_CLUSTER = "PROPS_GET_MAP_CLUSTER"
export const PROPS_GET_MAP_CLUSTER_SUCCESS = "PROPS_GET_MAP_CLUSTER_SUCCESS"
export const PROPS_GET_MAP_CLUSTER_ERROR = "PROPS_GET_MAP_CLUSTER_ERROR"
export const PROPS_GET_MAP_NEAR = "PROPS_GET_MAP_NEAR"
export const PROPS_GET_MAP_NEAR_SUCCESS = "PROPS_GET_MAP_NEAR_SUCCESS"
export const PROPS_GET_MAP_NEAR_ERROR = "PROPS_GET_MAP_NEAR_ERROR"

export const PROPS_LIKED = "PROPS_LIKED"
export const PROPS_LIKED_SUCCESS = "PROPS_LIKED_SUCCESS"
export const PROPS_LIKED_ERROR = "PROPS_LIKED_ERROR"
export const PROPS_VIEWED = "PROPS_VIEWED"
export const PROPS_VIEWED_SUCCESS = "PROPS_VIEWED_SUCCESS"
export const PROPS_VIEWED_ERROR = "PROPS_VIEWED_ERROR"
export const PROPS_STATS = "PROPS_STATS"
export const PROPS_STATS_SUCCESS = "PROPS_STATS_SUCCESS"
export const PROPS_STATS_ERROR = "PROPS_STATS_ERROR"

export const PROPS_UPDATE_PROPERTY = "PROPS_UPDATE_PROPERTY";
export const PROPS_UPDATE_PROPERTY_SUCCESS = "PROPS_UPDATE_PROPERTY_SUCCESS";
export const PROPS_UPDATE_PROPERTY_ERROR = "PROPS_UPDATE_PROPERTY_ERROR";

/* POSTS APP*/
export const POSTS_GET_LIST = "POSTS_GET_LIST";
export const POSTS_GET_LIST_SUCCESS = "POSTS_GET_LIST_SUCCESS";
export const POSTS_GET_LIST_ERROR = "POSTS_GET_LIST_ERROR";
export const POSTS_GET_POST = "POSTS_GET_POST";
export const POSTS_GET_POST_SUCCESS = "POSTS_GET_POST_SUCCESS";
export const POSTS_GET_POST_ERROR = "POSTS_GET_POST_ERROR";

export const POSTS_CLEAR_FILTERS = "POSTS_CLEAR_FILTERS";
export const POSTS_SET_FILTERS = "POSTS_SET_FILTERS";

export const SET_FORM = "SET_FORM"
export const CLEAR_FORM = "CLEAR_FORM"

export const POSTS_GET_CATS_LIST = "POSTS_GET_CATS_LIST";
export const POSTS_GET_LIST_CATS_SUCCESS = "POSTS_GET_LIST_CATS_SUCCESS";
export const POSTS_GET_LIST_CATS_ERROR = "POSTS_GET_LIST_CATS_ERROR";

export const POSTS_GET_RECENT = "POSTS_GET_RECENT";
export const POSTS_GET_RECENT_SUCCESS = "POSTS_GET_RECENT_SUCCESS";
export const POSTS_GET_RECENT_ERROR = "POSTS_GET_RECENT_ERROR";

export const CONTENT_FAQS_GET = "CONTENT_FAQS_GET";
export const CONTENT_FAQS_GET_SUCCESS = "CONTENT_FAQS_GET_SUCCESS";
export const CONTENT_FAQS_GET_ERROR = "CONTENT_FAQS_GET_ERROR";

/* Content APP*/
export const CONTENT_FOOTER_GET = "CONTENT_FOOTER_GET";
export const CONTENT_FOOTER_GET_SUCCESS = "CONTENT_FOOTER_GET_SUCCESS";
export const CONTENT_FOOTER_GET_ERROR = "CONTENT_FOOTER_GET_ERROR";

export const CONTENT_REGIONS_GET = "CONTENT_REGIONS_GET";
export const CONTENT_REGIONS_GET_SUCCESS = "CONTENT_REGIONS_GET_SUCCESS";
export const CONTENT_REGIONS_GET_ERROR = "CONTENT_REGIONS_GET_ERROR";

export const CONTENT_REGION_GET = "CONTENT_REGION_GET";
export const CONTENT_REGION_GET_SUCCESS = "CONTENT_REGION_GET_SUCCESS";
export const CONTENT_REGION_GET_ERROR = "CONTENT_REGION_GET_ERROR";

export const PROPERTY_TYPES_GET = "PROPERTY_TYPES_GET";
export const PROPERTY_TYPES_GET_SUCCESS = "PROPERTY_TYPES_GET_SUCCESS";
export const PROPERTY_TYPES_GET_ERROR = "PROPERTY_TYPES_GET_ERROR";

export const PROPERTY_FEATURES_GET = "PROPERTY_FEATURES_GET"
export const PROPERTY_FEATURES_GET_SUCCESS = "PROPERTY_FEATURES_GET_SUCCESS"
export const PROPERTY_FEATURES_GET_ERROR = "PROPERTY_FEATURES_GET_ERROR"

export const CONTENT_NAV_GET = "CONTENT_NAV_GET";
export const CONTENT_NAV_GET_SUCCESS = "CONTENT_NAV_GET_SUCCESS";
export const CONTENT_NAV_GET_ERROR = "CONTENT_NAV_GET_ERROR";

/* Filters APP*/
export const SET_FILTERS = "SET_FILTERS";
export const CLEAR_FILTERS = "CLEAR_FILTERS";

export const FILTERS_ADD_ITEM = "FILTERS_ADD_ITEM"
export const FILTERS_ADD_ITEM_SUCCESS = "FILTERS_ADD_ITEM_SUCCESS"
export const FILTERS_ADD_ITEM_ERROR = "FILTERS_ADD_ITEM_ERROR"
export const FILTERS_GET_LIST = "FILTERS_GET_LIST"
export const FILTERS_GET_LIST_SUCCESS = "FILTERS_GET_LIST_SUCCESS"
export const FILTERS_GET_LIST_ERROR = "FILTERS_GET_LIST_ERROR"
export const FILTERS_DELETE = "FILTERS_DELETE"
export const FILTERS_DELETE_SUCCESS = "FILTERS_DELETE_SUCCESS"
export const FILTERS_DELETE_ERROR = "FILTERS_DELETE_ERROR"
export const FILTERS_SUBSCRIBE = "FILTERS_SUBSCRIBE"
export const FILTERS_SUBSCRIBE_SUCCESS = "FILTERS_SUBSCRIBE_SUCCESS"
export const FILTERS_SUBSCRIBE_ERROR = "FILTERS_SUBSCRIBE_ERROR"
export const FILTERS_EDIT = "FILTERS_EDIT"
export const FILTERS_EDIT_SUCCESS = "FILTERS_EDIT_SUCCESS"
export const FILTERS_EDIT_ERROR = "FILTERS_EDIT_ERROR"
export const FILTERS_GET_FILTER = "FILTERS_GET_FILTER"
export const FILTERS_GET_FILTER_SUCCESS = "FILTERS_GET_FILTER_SUCCESS"
export const FILTERS_GET_FILTER_ERROR = "FILTERS_GET_FILTER_ERROR"
export const FILTERS_SUBSCRIBE_TOGGLE="FILTERS_SUBSCRIBE_TOGGLE"
export const FILTERS_SET_FILTER = "FILTERS_SET_FILTER"
export const SET_PREV_FILTERS = "SET_PREV_FILTERS"

export const GROUPS_GET_LIST = "GROUPS_GET_LIST"
export const GROUPS_GET_LIST_SUCCESS = "GROUPS_GET_LIST_SUCCESS"
export const GROUPS_GET_LIST_ERROR = "GROUPS_GET_LIST_ERROR"
export const GROUPS_ADD_ITEM = "GROUPS_ADD_ITEM"
export const GROUPS_ADD_ITEM_SUCCESS = "GROUPS_ADD_ITEM_SUCCESS"
export const GROUPS_ADD_ITEM_ERROR = "GROUPS_ADD_ITEM_ERROR"
export const GROUPS_DELETE = "GROUPS_DELETE"
export const GROUPS_DELETE_SUCCESS = "GROUPS_DELETE_SUCCESS"
export const GROUPS_DELETE_ERROR = 'GROUPS_DELETE_ERROR'
export const GROUPS_PROPS = "GROUPS_PROPS"
export const GROUPS_PROPS_SUCCESS = "GROUPS_PROPS_SUCCESS"
export const GROUPS_PROPS_ERROR = "GROUPS_PROPS_ERROR"
export const GROUPS_EDIT = "GROUPS_EDIT"
export const GROUPS_EDIT_SUCCESS = "GROUPS_EDIT_SUCCESS"
export const GROUPS_EDIT_ERROR = "GROUPS_EDIT_ERROR"
export const GROUPS_GET_GROUP = "GROUPS_GET_GROUP"
export const GROUPS_GET_GROUP_SUCCESS = "GROUPS_GET_GROUP_ERROR"
export const GROUPS_GET_GROUP_ERROR = "GROUPS_GET_GROUP_ERROR"
export const GROUPS_SET_PROPS = "GROUPS_SET_PROPS"
export const GROUPS_CLEAR = "GROUPS_CLEAR"

export const APPLICATIONS_GET_LIST = "APPLICATIONS_GET_LIST"
export const APPLICATIONS_GET_LIST_SUCCESS = "APPLICATIONS_GET_LIST_SUCCESS"
export const APPLICATIONS_GET_LIST_ERROR = "APPLICATIONS_GET_LIST_ERROR"
export const APPLICATIONS_ADD_ITEM = "APPLICATIONS_ADD_ITEM"
export const APPLICATIONS_ADD_ITEM_SUCCESS = "APPLICATIONS_ADD_ITEM_SUCCESS"
export const APPLICATIONS_ADD_ITEM_ERROR = "APPLICATIONS_ADD_ITEM_ERROR"
export const APPLICATIONS_DELETE = "APPLICATIONS_DELETE"
export const APPLICATIONS_DELETE_SUCCESS = "APPLICATIONS_DELETE_SUCCESS"
export const APPLICATIONS_DELETE_ERROR = 'APPLICATIONS_DELETE_ERROR'
export const APPLICATIONS_EDIT = "APPLICATIONS_EDIT"
export const APPLICATIONS_EDIT_SUCCESS = "APPLICATIONS_EDIT_SUCCESS"
export const APPLICATIONS_EDIT_ERROR = "APPLICATIONS_EDIT_ERROR"
export const APPLICATIONS_GET_APPLICATION = "APPLICATIONS_GET_APPLICATION"
export const APPLICATIONS_GET_APPLICATION_SUCCESS = "APPLICATIONS_GET_APPLICATION_SUCCESS"
export const APPLICATIONS_GET_APPLICATION_ERROR = "APPLICATIONS_GET_APPLICATION_ERROR"
export const APPLICATIONS_GET_PDF = "APPLICATIONS_GET_PDF"
export const APPLICATIONS_GET_PDF_SUCCESS = "APPLICATIONS_GET_PDF_SUCCESS"
export const APPLICATIONS_GET_PDF_ERROR = "APPLICATIONS_GET_PDF_ERROR"
export const APPLICATIONS_STORE_DRAFT = "APPLICATIONS_STORE_DRAFT"

export const BUDGETS_GET_LIST = 'BUDGETS_GET_LIST'
export const BUDGETS_GET_LIST_SUCCESS = 'BUDGETS_GET_LIST_SUCCESS'
export const BUDGETS_GET_LIST_ERROR = 'BUDGETS_GET_LIST_ERROR'
export const BUDGETS_ADD_ITEM = 'BUDGETS_ADD_ITEM'
export const BUDGETS_ADD_ITEM_SUCCESS = 'BUDGETS_ADD_ITEM_SUCCESS'
export const BUDGETS_ADD_ITEM_ERROR = 'BUDGETS_ADD_ITEM_ERROR'
export const BUDGETS_DELETE = 'BUDGETS_DELETE'
export const BUDGETS_DELETE_SUCCESS = 'BUDGETS_DELETE_SUCCESS'
export const BUDGETS_DELETE_ERROR = 'BUDGETS_DELETE_ERROR'
export const BUDGETS_EDIT = 'BUDGETS_EDIT'
export const BUDGETS_EDIT_SUCCESS = 'BUDGETS_EDIT_SUCCESS'
export const BUDGETS_EDIT_ERROR = 'BUDGETS_EDIT_ERROR'
export const BUDGETS_GET_BUDGET = 'BUDGETS_GET_BUDGET'
export const BUDGETS_GET_BUDGET_SUCCESS = 'BUDGETS_GET_BUDGET_SUCCESS'
export const BUDGETS_GET_BUDGET_ERROR = 'BUDGETS_GET_BUDGET_ERROR'

export * from "./auth/actions";
export * from "./properties/actions";
export * from "./posts/actions";
export * from "./content/actions";
export * from "./filters/actions";
export * from "./groups/actions";
export * from "./users/actions";
export * from "./applications/actions";
export * from "./budgets/actions";
