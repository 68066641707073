import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  CHECK_AUTH_STATE,
  CHECK_AUTH_STATE_SUCCESS,
  CHECK_AUTH_STATE_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  REFRESH_USER,
  REFRESH_USER_SUCCESS,
  REFRESH_USER_ERROR,
  SIGN_IN_WITH_REDIRECT,
  SIGN_IN_WITH_REDIRECT_SUCCESS,
  SIGN_IN_WITH_REDIRECT_ERROR,
  ACCOUNT_CLEAR
} from '../actions';

// user login

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history }
});
export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message }
});


// user logout

export const logoutUser = (history) => {
  
  // clear all local storage to do with auth
  localStorage.removeItem('user_token');
  localStorage.removeItem('user_account');
  localStorage.removeItem('user_token_expires');

  return {
    type: LOGOUT_USER,
    payload: { history }
  }
};

// register user

export const registerUser = (user, history, skipToggle) => ({
  type: REGISTER_USER,
  payload: { user, history, skipToggle }
})
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user
})
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message }
})

// user forgot password

export const forgotPassword = (forgotUserMail, history) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history }
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message }
});

// user reset password

export const resetPassword = ({resetPasswordCode,newPassword,history}) => ({
  type: RESET_PASSWORD,
  payload: { resetPasswordCode,newPassword,history }
});
export const resetPasswordSuccess = (newPassword) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: newPassword
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message }
});


// refresh user

export const refreshUser = () => ({
  type: REFRESH_USER
});
export const refreshUserSuccess = (user) => ({
  type: REFRESH_USER_SUCCESS,
  payload: user
});
export const refreshUserError = (message) => ({
  type: REFRESH_USER_ERROR,
  payload: { message }
});

// check user authentication

export const checkAuthState = (user) => ({
  type: CHECK_AUTH_STATE,
  payload: user
});

export const checkAuthStateSuccess = () => ({
  type: CHECK_AUTH_STATE_SUCCESS
});

export const checkAuthStateError = () => ({
  type: CHECK_AUTH_STATE_ERROR
});


// sign user in after redirected

export const signInWithRedirect = (payload) => ({
  type: SIGN_IN_WITH_REDIRECT,
  payload: payload
});

export const signInWithRedirectSuccess = () => ({
  type: SIGN_IN_WITH_REDIRECT_SUCCESS,
});

export const signInWithRedirectError = () => ({
  type: SIGN_IN_WITH_REDIRECT_ERROR,
});

// clear account
export const clearAccount = (params) => ({
  type: ACCOUNT_CLEAR,
  payload: params
});