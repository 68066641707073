import { createSlice } from '@reduxjs/toolkit';

import { NotificationManager } from "../../components/common/react-notifications";

// utils
import api from "../api";
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  error: null,
  budget: null,
  budgets: [],
};

const slice = createSlice({
  name: 'budget',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.loading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    // GET BUDGETS
    getBudgetsSuccess(state, action) {
      state.loading = false;
      state.budgets = action.payload;
    },

    // GET BUDGET
    getBudgetSuccess(state, action) {
      state.loading = false;
      state.budget = action.payload;
    },

    // CREATE BUDGET
    createBudgetSuccess(state, action) {
      state.loading = false;
      const newBudget = action.payload.response;
      state.budgets.results = [...state.budgets.results, newBudget];
    },

    // UPDATE BUDGET
    updateBudgetSuccess(state, action) {
      state.loading = false;
      const newBudget = action.payload.response;
      const index = state.budgets.results.findIndex(x => x._id == newBudget._id);
      state.budgets.results[index] = newBudget;
    },

    // DELETE BUDGET
    deleteBudgetSuccess(state, action) {
      state.loading = false;
      const deletedId = action.payload;
      const newBudgets = state.budgets.results.filter(i => i._id !== deletedId);
      state.budgets.results = [...newBudgets];
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function createBudget(payload) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
     const { data } = await api.post('/budgets', payload, true);
      await dispatch(slice.actions.createBudgetSuccess(data));
      NotificationManager.success(
        "Budget created",
        "Budget was successfully created",
        3000,
        null,
        null,
        ''
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBudgets(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.get('/budgets', params, true);
      dispatch(slice.actions.getBudgetsSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateBudget(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.put('/budgets', params.id, params.updates, true);
      dispatch(slice.actions.updateBudgetSuccess(data));
      NotificationManager.success(
        "Budget updated",
        "Budget was successfully updated",
        3000,
        null,
        null,
        ''
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBudget(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.getById('/budgets', params, true);
      dispatch(slice.actions.getBudgetSuccess(data.response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteBudget(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.delete('/budgets', params, true);
      dispatch(slice.actions.deleteBudgetSuccess(params));
      NotificationManager.success(
        "Budget deleted",
        "Budget was successfully deleted",
        3000,
        null,
        null,
        ''
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}