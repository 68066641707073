import { auth, firebase } from './firebase';
import api from "../redux/api";
import axios from 'axios';
import $h from '../utils/helper';
import { NotificationManager } from "../components/common/react-notifications";
import { dispatch } from '../redux/store';

// create user
export const createUser =  async ({ user, account }) => {
  const newUser = await api.post('/users', user, true);
  const newAccount = await createAccount(account, newUser.data.response._id);
}

// format account
export const createAccount = (data, user) => {
  const { email, password, uid } = data;
  return api.post('/accounts', {
    email,
    user,
    password,
    uid,
 });
}

// fetch user
export const fetchUser = () => api.get('/accounts/me', {}, true);

// send guest email
export const sendGuestEmail = (data) => api.post('/users/guest', data, true);

// login
export const login = (email) => {
  return new Promise(async (resolve, reject ) => {
    try {
      const result = await api.post('/accounts/login', { email }, true);
      resolve(result);
    } catch (e) {
      NotificationManager.warning(
        e,
        "Could not signin",
        5000,
        null,
        null,
        ''
      );
      reject(e);
    }
  });
};

export const loginUser = ({ user, history, skipToggle = false }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { 
        password,
        LoginType,
        social,
      } = user;

      let loginUser;
      let email;
          
      if (LoginType === 'social') {
        
        localStorage.setItem('operationType', 'login');

        loginUser = await loginWithSocial(social); 
        if (!loginUser.user) {
          NotificationManager.warning(
            loginUser.message,
            "Warning",
            5000,
            null,
            null,
            ''
          );
          reject('Could not sign in with social');
        }

        email = loginUser.user.email;

      }  else {
        email = user.email
        loginUser = await loginWithEmailPassword(email, password);
      }
  
      if (!loginUser.message) {
        // const token =  await loginUser.user.getIdToken();
        // await setRequestToken(token);
        // await login(email);

        resolve({
          uid: loginUser.user.uid,
        });

        if (history && !$h.isNative()) history.push('/');
          
      } else {

        NotificationManager.warning(
          loginUser.message || 'Could not sign in',
          "Warning",
          5000,
          null,
          null,
          '',
        );

        reject(loginUser.message);
      }

    } catch (error) {

      NotificationManager.warning(
        error.message || 'Could not sign in',
        "Warning",
        5000,
        null,
        null,
        '',
      );
  
    }
  });
}

// logout
export const logout = () => auth.signOut();

// clear session
export const clearSession = () => {
  // clearAccount()
}

// clear and logout
export const clearAndLogout = () => {
  // clearAccount()
}

// fetch and see if external user exists using social
export const registerWithSocial = (user) => {
  
  const { 
    social,  
    photoURL,
  } = user;

  return new Promise(async (resolve, reject ) => {
    
    try {
      const externalUser = await loginWithSocial(social); 
        
      if (externalUser.message) return reject('Error fetching external user');

      const { additionalUserInfo } = externalUser;
      const { profile } = additionalUserInfo;

      const first_name = profile.given_name ? profile.given_name : user.dislayName ? $h.addSpaces(user.displayName) : profile.first_name
      const last_name = profile.family_name ? profile.family_name : user.dislayName ? $h.addSpaces(user.displayName) : profile.last_name
      const password = $h.generatePassword();
      const userName = `${first_name.toLowerCase()}.${last_name.toLowerCase()}`;
      const avatar = (profile.picture && profile.picture.data) ? profile.picture.data.url : photoURL ? photoURL : profile.picture;

      resolve({
        user: {
          first_name: first_name,
          last_name: last_name,
          user_name: userName,
          role: "Buyer",
          email: externalUser.user.email,
          avatar: {
              url: avatar,
          }
        },
        account: {
          email: externalUser.user.email,
          password: password,
          uid: externalUser.user.uid
        }
      });

    } catch (e) {
      reject(e);
    }
  });
}

export const registerWithEmail = (user) => {
  
  const {  
    email, 
    password,
    first_name,
    last_name,
  } = user;

  return new Promise(async (resolve, reject) => {
    try {
      const externalUser = await registerWithEmailPassword(email, password);
      if (externalUser.message) reject(externalUser.message);

      resolve({
        user: {
          first_name,
          last_name,
          email: email,
          user_name: `${first_name.toLowerCase()}.${last_name.toLowerCase()}`,
          role: "Buyer"
        },
        account: {
          email: email,
          password: password,
          uid: externalUser.user.uid,
        }
      })

    } catch (e) {
      reject(e);
    }
  });
}

// register
export const register = ({ 
  history, 
  user,
  skipToggle = false,
}) => {
  return new Promise(async (resolve, reject ) => {
    const {  
      registerType, 
    } = user;

    let data;

    try {
      if (registerType === 'social') {
        localStorage.setItem('operationType', 'register');
        data = await registerWithSocial(user);
      } else {
        data = await registerWithEmail(user);
      }
      await createUser(data);
      const newUser = await storeOrClearAuth(auth.currentUser);
      if (history && !$h.isNative()) history.push('/')

      if (registerType === 'social' || registerType === 'guest') { 
        await sendGuestEmail(
          {
            first_name: data.user.first_name,
            last_name: data.user.last_name,
            email: data.account.email,
            password: data.account.password,
            registerType: registerType
          }
        ); 
      }

      if (!skipToggle) {
        // dispatch({type: 'TOGGLE_REGISTER_MODAL', payload: {}})
      }
      resolve(newUser);
    } catch (e) {
      NotificationManager.warning(
        e.message || 'Could not register user',
        "Could not register user",
        5000,
        null,
        null,
        ''
      );
      reject(e);
    }
  })
}

// login with email password
export const loginWithEmailPassword = (email, password) => auth.signInWithEmailAndPassword(email, password);

// register with email password
export const registerWithEmailPassword = (email, password) => auth.createUserWithEmailAndPassword(email, password);

// register with social

// login with social
export const loginWithSocial = (social) => {
  
  let provider = null
  if (social === "google") { 
    provider = new firebase.auth.GoogleAuthProvider();
  } else if (social === "facebook") {
    provider = new firebase.auth.FacebookAuthProvider();
  } else if (social === "apple") {
    provider = new firebase.auth.OAuthProvider('apple.com');
  }

  if (!$h.isNative()) {
    return auth.signInWithPopup(provider);
  }

  return auth.signInWithRedirect(provider); 
}

// format user

// email exists
export const emailExists = ({ email }) => {
  return api.get('/accounts/exists', { 
    email 
  });
}

export const setRequestToken = (token) => {
  return new Promise( async (resolve, reject) => {
    try {
      await axios.interceptors.request.use(config => {
        config.headers.authorization = `Bearer ${token}`
        return config
      }, (error) => {
        reject(error)
      })
      resolve(token);
    } catch (e) {
      reject(e);
    }
  });
}

// evaluate session
export const storeOrClearAuth = (account) => {
  return new Promise( async (resolve, reject) => {
    try {
      if (!account) {
        await logout();
        reject(false);
      }
      // check to see if current user otherwise authorize again 
      const token =  await account.getIdToken();
      // set request token in header
      await setRequestToken(token);
      // fetch user profile info
      const user = await fetchUser();
      resolve(user);
    } catch (e) {
      reject(false);
    } 
  });
}

// sign in with redirect
export const signInWithRedirect = ({ user, operationType, additionalUserInfo }) => {

  return new Promise( async (resolve, reject) => {
    const { profile } = additionalUserInfo;
    try {
      
      if (operationType !== 'register') {
        resolve(user);
      }

      const first_name = profile.given_name ? profile.given_name : user.displayName ? user.displayName.split(' ').slice(0, -1).join(' ') : profile.first_name;
      const last_name = profile.family_name ? profile.family_name : user.displayName ? user.displayName.split(' ').slice(-1).join(' ') : profile.last_name;
      const password = $h.generatePassword();

      const data = { 
        user: {
          first_name: first_name,
          last_name: last_name,
          email: user.email,
          user_name: `${first_name.toLowerCase()}.${last_name.toLowerCase()}`,
          role: "Buyer",
          avatar: {
            url: (profile.picture && profile.picture.data) ? profile.picture.data.url : user.photoURL ? user.photoURL : profile.picture
          }
        },
        account: {
          email: user.email,
          password: password,
          uid: user.uid,
        }
      };

      const user = await createUser(data); 

      await sendGuestEmail({
        first_name: data.user.first_name,
        last_name: data.user.last_name,
        email: data.account.email,
        password: data.account.password,
        registerType: 'social'
      }); 
      
      resolve(user);

    } catch (error) {
      reject(error);
    }

  });
}

// refresh user

// forgot password
export const forgotPassword = (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await auth.sendPasswordResetEmail(email);

      NotificationManager.success(
        'Email verification has been sent!',
        "Success",
        5000,
        null,
        null,
        ''
      );

      resolve(response);
    } catch(e) {

      NotificationManager.warning(
        e.message,
        "Forgot Password Error",
        3000,
        null,
        null,
        '',
      );

      reject(e);
    }
  })
}

// reset password
export const resetPassword = ({
  resetPasswordCode,
  newPassword,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await auth.confirmPasswordReset(resetPasswordCode, newPassword);

      NotificationManager.success(
        "Please login with your new password.",
        "Reset Password Success",
        3000,
        null,
        null,
        '',
      );

      resolve(response);
    } catch(e) {

      NotificationManager.warning(
        e.message,
        "Reset Password Error",
        3000,
        null,
        null,
        '',
      );

      reject(e);
    }
  })
}

export default {
  createUser,
  sendGuestEmail,
  login,
  loginUser,
  clearAndLogout,
  storeOrClearAuth,
  logout,
  loginWithSocial,
  loginWithEmailPassword,
  registerWithEmailPassword,
  register,
  emailExists,
  signInWithRedirect,
  forgotPassword,
  resetPassword,
  fetchUser,
};
