import { useState, createContext } from 'react';
import _ from 'lodash';

// ----------------------------------------------------------------------

const initialState = {
  price_min: null,
  price_max: null,
  type: null,
  search: '',
  categories: [],
  bedrooms: null,
  bathrooms: null,
  age_min: null,
  age_max: null,
  parking_spaces: null,
  sqft_min: null,
  sqft_max: null,
  lot_min: null,
  lot_max: null,
  basement: null,
  garage: null,
  region: null,
  country: null,
  city: null,
  lat: null,
  lng: null,
  features: null,
  boundingBox: null,
  bounds: null,
  address: null,
};

const FiltersContext = createContext(initialState);

function FiltersProvider({ children }) {

  const [filters, setFilters] = useState({
    ...initialState,
  });

  const [form, setForm] = useState({
    ...initialState,
  });

  const [prevFilters, setPrevFilters] = useState({
    ...initialState,
  });

  const onChangeFilters = (newFilters, updateForm = false) => {
    const combined = {...filters, ...newFilters};
    const isEqual = _.isEqual(combined, filters);
    if (isEqual) return;
    if (updateForm) onChangeForm(filters);
    console.trace();
    setPrevFilters({
      ...prevFilters,
      ...filters,
    });
    setFilters(combined);
  };

  const clearFilters = () => {
    setPrevFilters(initialState);
    setFilters(initialState);

    onClearForm();
  }

  const onChangeForm = (newForm) => {
    const combined = {...form, ...newForm};
    const isEqual = _.isEqual(combined, form);
    if (isEqual) return;
    setForm(combined);
  }

  const onClearForm = () => {
    setForm(initialState);
  }

  return (
    <FiltersContext.Provider
      value={{
        filters,
        form,
        prevFilters,
        onChangeFilters,
        onChangeForm,
        onClearForm,
        clearFilters,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
}

export { FiltersContext, FiltersProvider };
