import { createSlice } from '@reduxjs/toolkit';

import { NotificationManager } from "../../components/common/react-notifications";

// utils
import api from "../api";
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  error: null,
  group: null,
  selected: null,
  properties: [],
  groups: [],
};

const slice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.loading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    // GET GROUPS
    getGroupsSuccess(state, action) {
      state.loading = false;
      state.groups = action.payload;
    },

    // GET GROUP
    getGroupSuccess(state, action) {
      state.loading = false;
      state.group = action.payload;
    },

    
    // CREATE GROUP
    createGroupSuccess(state, action) {
      state.loading = false;
      const newGroup = action.payload.response;
      state.groups.results = [...state.groups.results, newGroup];
    },

    // UPDATE GROUP
    updateGroupSuccess(state, action) {
      state.loading = false;
      const newGroup = action.payload.response;
      const index = state.groups.results.findIndex(x => x._id == newGroup._id);
      state.groups.results[index] = newGroup;
    },

    // DELETE GROUP
    deleteGroupSuccess(state, action) {
      state.loading = false;
      const deletedId = action.payload;
      const newGroups = state.groups.results.filter(i => i._id !== deletedId);
      state.groups.results = [...newGroups];
    },

    storeSelectedProperty(state, action) {
      state.selected = action.payload;
    },

    clearGroup(state) {
      state.group = null;
    },

    setGroupProperties(state, action) {
      state.group = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function createGroup(payload) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
     const { data } = await api.post('/groups', payload, true);
      dispatch(slice.actions.createGroupSuccess(data));
      NotificationManager.success(
        "Listing group created",
        "Listing group was successfully created",
        3000,
        null,
        null,
        ''
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGroups(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.get('/groups', params, true);
      dispatch(slice.actions.getGroupsSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateGroup(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.put('/groups', params.id, params.updates, true);
      dispatch(slice.actions.updateGroupSuccess(data));
      NotificationManager.success(
        "Listing group updated",
        "Listing group was successfully updated",
        3000,
        null,
        null,
        ''
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGroup(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.getById('/groups', params, true);
      dispatch(slice.actions.getGroupSuccess(data.response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteGroup(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.delete('/groups', params, true);
      dispatch(slice.actions.deleteGroupSuccess(params));
      NotificationManager.success(
        "Listing group deleted",
        "Listing group was successfully deleted",
        3000,
        null,
        null,
        ''
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addPropertyToGroup(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = api.put('/groups/add', params.id, { property: params.property }, true);
      dispatch(slice.actions.getGroupSuccess(data));
      NotificationManager.success(
        "Listing group property added",
        "Listing group was successfully property added",
        3000,
        null,
        null,
        ''
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function storeSelectedProperty(property) {
  return async () => {
    dispatch(slice.actions.storeSelectedProperty(property));
  }
}

// ----------------------------------------------------------------------

export function clearGroup() {
  return async () => {
    dispatch(slice.actions.clearGroup());
  }
}

// ----------------------------------------------------------------------

export function setGroupProperties(properties) {
  return async () => {
    dispatch(slice.actions.setGroupProperties(properties));
  }
}