import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// slices
import applicationReducer from './slices/applications';
import authReducer from './slices/auth';
import budgetReducer from './slices/budgets';
import contentReducer from './slices/content';
import filterReducer from './slices/filters';
import groupReducer from './slices/groups';
import postReducer from './slices/posts';
import propertyReducer from './slices/properties';
import userReducer from './slices/user';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  application: applicationReducer,
  auth: authReducer,
  budget: budgetReducer,
  content: persistReducer(rootPersistConfig, contentReducer),
  filter: filterReducer,
  group: groupReducer,
  post: postReducer,
  property: propertyReducer,
  user: userReducer,
});

export { rootPersistConfig, rootReducer };
