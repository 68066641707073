import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import  { BreakpointProvider } from 'react-socks';
import {
  SettingsProvider,
} from './contexts/SettingsContext';
import {
  ModalProvider,
} from './contexts/ModalContext';
import { FiltersProvider } from './contexts/FiltersContext';

const App = React.lazy(() => import(/* webpackChunkName: "App" */'./App' ));

ReactDOM.render(
  <FiltersProvider>
    <ModalProvider>
      <SettingsProvider>
        <Provider store={store}>
          <Suspense fallback={<div className="loading" />}>
            <BreakpointProvider>
              <App />
            </BreakpointProvider>
          </Suspense>
        </Provider>
      </SettingsProvider>
    </ModalProvider>
  </FiltersProvider>,
  document.getElementById('root')
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
