import { createSlice } from '@reduxjs/toolkit';
import { refreshUser, logoutUser } from '../actions';

// utils
import api from "../api";
//
import { dispatch } from '../store';

import { NotificationManager } from "../../components/common/react-notifications";

// ----------------------------------------------------------------------

const initialState = {
	user: null,
	loading: false,
	professionals: null,
	error: null,
};

const updateViews =  (state, payload) => {
  const user = {...state.user}
  user.viewed_properties.push(payload)

  return Object.assign({}, state, {user: user}) ;
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.loading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    // GET GROUPS
    getGroupsSuccess(state, action) {
      state.loading = false;
      state.groups = action.payload;
    },

    // GET GROUP
    getUserByIdSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },

    deleteUserSuccess(state, action) {
      state.loading = false;
    },

    toggleUserSubscriptionSuccess(state, action) {
      state.loading = false;
    },

    updateUserSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },

    getProfessionalsSuccess(state, action) {
      // state.loading = false;
      state.professionals = action.payload;
    },

    viewPropertySuccess(state, action) {
      updateViews(state, action.payload);
    },

    setUserProfile(state, action) {
      state.profile = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

const uploadFiles = async (files) => {
  const promises = files.map((file, k) => {
    if (file.s3_key || file._id || (file.file && file.file.s3_key)) { 
      return {
        data: file
      };
    }
    return api.upload('/applications/file', file.file, true);
  })
  return Promise.all(promises);
};

const addFiles = async (payload) => {
  return new Promise(async (resolve, reject ) => {
    try {
      const { 
        nht,
        ability_to_pay,
        mortgage,
        identifications,
        utilities,
        pay_stubs,
      } = payload;

      if (nht && nht.find(e => !e.s3_key)) {
        const files = await uploadFiles(nht);
        files.forEach((file, k) => {
          payload.nht[k] = file.data.response || file.data;
        })
      } 

      if (ability_to_pay && ability_to_pay.find(e => !e.s3_key)) {
        const files = await uploadFiles(ability_to_pay);
        files.forEach((file, k) => {
          payload.ability_to_pay[k] = file.data.response || file.data;
        })
      } 

      if (mortgage && mortgage.find(e => !e.s3_key)) {
        const files = await uploadFiles(mortgage);
        files.forEach((file, k) => {
          payload.mortgage[k] = file.data.response || file.data;
        })
      } 

      if (identifications && identifications.find(e => !e.s3_key)) {
        const files = await uploadFiles(identifications);
        files.forEach((file, k) => {
          payload.identifications[k] = file.data.response || file.data;
        })
      } 

      if (utilities && utilities.find(e => !e.s3_key)) {
        const files = await uploadFiles(utilities);
        files.forEach((file, k) => {
          if (!file.data.file && file.data.file !== '') {
            payload.utilities[k].file = file.data.response || file.data.file;
          }
        })
      } 

      if (pay_stubs && pay_stubs.find(e => !e.s3_key)) {
        const files = await uploadFiles(pay_stubs);
        files.forEach((file, k) => {
          payload.pay_stubs[k] = file.data.response || file.data;
        })
      } 
      resolve(payload)
    } catch (error) {
      reject(error)
    }
  });
}

// ----------------------------------------------------------------------

export function getProfessionals() {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.get('/buyers/professionals', {}, true);
      dispatch(slice.actions.getProfessionalsSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.get(`/users/${id}/profile`, {}, true);
      dispatch(slice.actions.getUserByIdSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMe(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.get('/users/me', {}, true);
      dispatch(slice.actions.getUserByIdSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateUser(payload) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const updated = await addFiles(payload); 
      const { data } = await api.post('/users/me', updated, true);
      dispatch(slice.actions.updateUserSuccess(data));
      await refreshUser();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

const uploadProfilePicToFirebase = async (data) => {
  const file = data[0].file
  return api.upload('/users/me/avatar', file, true);
};


// ----------------------------------------------------------------------

export function updateProfilePic(payload) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await uploadProfilePicToFirebase({
        file: payload
      });
      const file = result[0].file
      const { data } = await  api.upload('/users/me/avatar', file, true);
      dispatch(slice.actions.updateUserSuccess(data));
      await refreshUser();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function viewProperty(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.post(`/buyers/view`, {
        id,
      }, true);
      dispatch(slice.actions.viewPropertySuccess(data));
      await refreshUser();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteUser() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.delete('/users/me', '', true);
      dispatch(slice.actions.deleteUserSuccess(data));
      await logoutUser();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setUserProfile(profile = {}) {
  return async () => {
    if (!profile || Object.keys(setUserProfile).length === 0) return;
    dispatch(slice.actions.setUserProfile(profile));
  }
}

// ----------------------------------------------------------------------

export function toggleUserSubscription(payload) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let url = '/buyers/subscribe';
      if (payload) {
        url += `/${payload}`
      } 
      const { data } = await api.get(url, '');
        
      NotificationManager.success(
        'Your subscription has been updated',
        "Success",
        5000,
        null,
        null,
        ''
      );

      dispatch(slice.actions.toggleUserSubscriptionSuccess(data));
      if(!payload) await refreshUser();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}