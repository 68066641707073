import { createSlice } from '@reduxjs/toolkit';

// utils
import { auth } from '../../services/firebase';
import api from "../api";
//
import { dispatch } from '../store';

import { NotificationManager } from "../../components/common/react-notifications";

import {
  createUser,
  fetchUser,
  sendGuestEmail,
  login,
  clearAndLogout,
  storeOrClearAuth,
  loginWithSocial,
  loginWithEmailPassword,
  register,
} from '../../services/auth';
import useModal from '../../hooks/useModal';

import generator from 'generate-password';

import { 
  getMe as getUserInfo, 
} from "../actions";


// on refresh if user local storage set
const session = localStorage.getItem('session');

// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  error: null,
  user: null,
  account: '',
  forgotUserMail: '',
  newPassword: '',
  resetPasswordCode: '',
  session: session ? true : false,
  signedIn: false,
  registering: false,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.loading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    // GET USER REGISTER
    registerUserSuccess(state, action) {
      state.signedIn = true;
      state.session = true;
      state.registering = false;
      state.loading = false;
    },

    // GET USER RESET PASSWORD
    resetPasswordSuccess(state, action) {
      state.newPassword = action.payload;
      state.resetPasswordCode = '';
      state.loading = false;
    },

    // CHECK THE CURRENT AUTH STATE
    checkAuthStateSuccess(state, action) {
      state.signedIn = true;
      state.session = true;
      state.loading = false;
    },

    // REFRESH A USER INFO
    refreshUserSuccess(state, action) {
      state.account = action.account;
      state.loading = false;
    },

    // USER LOGIN
    loginUserSuccess(state, action) {
      state.loading = false;
    },

    // USER FORGOT PASSWORD
    forgotPasswordSuccess(state, action) {
      state.forgotUserMail = action.payload;
      state.loading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function loginUser(payload) {
  return async () => {
    const { password, LoginType, social } = payload.user;
    const { history, skipToggle = false } = payload;

    dispatch(slice.actions.startLoading());
    try {
      let loginUser;
      let email;
      console.log(LoginType, 'LoginType');
      if (LoginType === 'social') {
        localStorage.setItem('operationType', 'login');
        loginUser = await loginWithSocial(social);

        if (!loginUser.user) {
            console.log(loginUser, "loginUser failure")
            NotificationManager.warning(
              loginUser.message,
              "Could not signin",
              5000,
              null,
              null,
              '',
            );
            dispatch(slice.actions.hasError(loginUser.message));
            return;
        }
        email = loginUser.user.email;
      }  else {
        email = payload.user.email;
        loginUser = await loginWithEmailPassword(email, password);
      }
      
      if (!loginUser.message) {
        await login(email);
        // store the retreived user.
        await dispatch(slice.actions.loginUserSuccess({ uid: loginUser.user.uid }));
        if (!skipToggle) {
          const { toggleModal } = useModal();
          console.log();
          toggleModal('register');
        }
        const isNative = localStorage.getItem('isNative')
        if (history && !isNative) history.push('/');
      } else {
        NotificationManager.warning(
          loginUser.message,
          "Could not signin",
          5000,
          null,
          null,
          '',
        );
        dispatch(slice.actions.hasError(loginUser.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function logout(payload) {
  return async () => {
    const { history } = payload;
    dispatch(slice.actions.startLoading());
    try {
      await clearAndLogout();
      if (history) history.push('/')
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function signInWithRedirect(payload) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { user, operationType, additionalUserInfo } = payload;
      if (operationType === 'register') {
        const first_name = additionalUserInfo.profile.given_name ? additionalUserInfo.profile.given_name : user.displayName ? user.displayName.split(' ').slice(0, -1).join(' ') : additionalUserInfo.profile.first_name
        const last_name = additionalUserInfo.profile.family_name ? additionalUserInfo.profile.family_name : user.displayName ? user.displayName.split(' ').slice(-1).join(' ') : additionalUserInfo.profile.last_name
        const password = generator.generate({
            length: 10,
            numbers: true
        });
  
        const data = [ {
            first_name: first_name,
            last_name: last_name,
            user_name: first_name.toLowerCase() + '.' + last_name.toLowerCase(),
            role: "Buyer",
            avatar: {
                url: (additionalUserInfo.profile.picture && additionalUserInfo.profile.picture.data) ? additionalUserInfo.profile.picture.data.url : user.photoURL ? user.photoURL : additionalUserInfo.profile.picture
            }
        }, {
            email: user.email,
            password: password,
            uid: user.uid
        }]
        
        await createUser(data);
        await sendGuestEmail({
          first_name: data[0].first_name,
          last_name: data[0].last_name,
          email: data[1].email,
          password: data[1].password,
          registerType: 'social'
        });
      }
   
      // store the retreived user.
      dispatch(slice.actions.loginUserSuccess());

      NotificationManager.warning(
          'Could not sign in with redirect',
          "Warning",
          5000,
          null,
          null,
          ''
      );
  
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function registerUser(payload) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { skipToggle } = payload
      await register(payload);

      const { toggleModal } = useModal();
      if (!skipToggle) await toggleModal('register');

      dispatch(slice.actions.registerUserSuccess(payload));
      if (!skipToggle) await toggleModal('onboarding');
    } catch (error) {
      console.log(error, 'jhh');
      NotificationManager.success(
        "Please check your email.",
        "Forgot Password Success",
        3000,
        null,
        null,
        ''
      );
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function forgotPassword(email) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const forgotPasswordStatus = await auth.sendPasswordResetEmail(email);
      if (!forgotPasswordStatus) {
        NotificationManager.success(
          "Please check your email.",
          "Forgot Password Success",
          3000,
          null,
          null,
          ''
        );
        dispatch(slice.actions.forgotPasswordSuccess());
      } else {
        NotificationManager.warning(
          'Could not update password',
          "Warning",
          5000,
          null,
          null,
          '',
        );
        dispatch(slice.actions.hasError(forgotPasswordStatus));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function resetPassword(payload) {
  const { newPassword, resetPasswordCode } = payload;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const resetPasswordStatus = await auth.confirmPasswordReset(resetPasswordCode, newPassword);
      if (!resetPasswordStatus) {
        dispatch(slice.actions.resetPasswordSuccess('success'));
      } else {
        dispatch(slice.actions.hasError(resetPasswordStatus));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function checkAuthState(payload) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await storeOrClearAuth(payload);
      dispatch(slice.actions.checkAuthStateSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function refreshUser() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const account = await fetchUser();
      await getUserInfo();
      dispatch(slice.actions.refreshUserSuccess(account.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
