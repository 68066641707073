import { useState, createContext } from 'react';

// ----------------------------------------------------------------------

const initialState = {
  modalQueue: {},
  modalMeta: {},
};

const ModalContext = createContext(initialState);

function ModalProvider({ children }) {

  const [modalQueue, setModalQueue] = useState(initialState.modalQueue);
  const [modalMeta, setModalMeta] = useState(initialState.modalMeta);

  const addToQueue = ({ reference = '', isOpen = false, meta = {} }) => {
    if (modalQueue[reference]) return;
    setModalQueue({
      ...modalQueue,
      [reference]: isOpen,
    });
    setModalMeta({
      ...modalMeta,
      [reference]: meta,
    });
  }

  const isOpen = (reference = '') => {
    return modalQueue[reference] ? modalQueue[reference] : false;
  }

  const closeModal = (reference = '') => {
    setModalQueue({
      ...modalQueue,
      [reference]: false,
    });
  }

  const openModal = (reference = '') => {
    setModalQueue({
      ...modalQueue,
      [reference]: true,
    });
  }

  const toggleModal = (reference = '') => {
    setModalQueue({
      ...modalQueue,
      [reference]: !modalQueue[reference],
    });
  }

  const updateModalMeta = (reference = '', meta = {}) => {
    setModalMeta({
      ...modalMeta,
      [reference]: {
        ...modalMeta[reference],
        ...meta,
      },
    });
  }

  return (
    <ModalContext.Provider
      value={{
        addToQueue,
        isOpen,
        closeModal,
        openModal,
        toggleModal,
        updateModalMeta,
        modalMeta,
        modalQueue,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

export { ModalProvider, ModalContext };
