import { useState, createContext } from 'react';
import _ from 'lodash';
import {
  defaultLocale,
  localeOptions,
} from '../utils/defaults/defaultValues';


// ----------------------------------------------------------------------

const isNative = localStorage.getItem('isNative');

const initialState = {
  limit: 10,
  isNative,
  page: 1,
  prevPage: 1,
  order: 'asc',
  orderBy: { column: "title", label: "Product Name" },
  prevOrderBy: { column: "title", label: "Product Name" },
  startIndex: 0,
  endIndex: 0,
  pageSizes: [10, 20, 30, 50, 100],
  appTitle: null,
  appBack: null,
  userCurrentLocation: null,
  metaTitle: null,
  metaDescription: null,
  locale: (localStorage.getItem('currentLanguage') &&
    localeOptions.filter(x=>x.id===localStorage.getItem('currentLanguage')).length>0) ? localStorage.getItem('currentLanguage') : defaultLocale,
};

const SettingsContext = createContext(initialState);

function SettingsProvider({ children }) {

  const [settings, setSettings] = useState({
    limit: initialState.limit,
    isNative: initialState.isNative,
    page: initialState.page,
    prevPage: initialState.prevPage,
    order: initialState.order,
    prevOrderBy: initialState.prevOrderBy,
    orderBy: initialState.orderBy,
    startIndex: initialState.startIndex,
    endIndex: initialState.endIndex,
    pageSizes: initialState.pageSizes,
    locale: initialState.locale,
    appTitle: initialState.appTitle,
    appBack: initialState.appBack,
    metaTitle: initialState.metaTitle,
    metaDescription: initialState.metaDescription,
    userCurrentLocation: initialState.userCurrentLocation,
  });

  const [prevSettings, setPrevSettings] = useState(settings);

  const onChangeSettings = (newSettings) => {
    const combined = {...settings, ...newSettings};
    const isEqual = _.isEqual(combined, settings);
    if (isEqual) return;
    setPrevSettings(settings);
    setSettings(newSettings);
  }

  const onChangePage = (page) => {
    if (settings.page === page) return;
    const startIndex = (page - 1) * settings.limit;
    const endIndex = page * settings.limit;

    onChangeSettings({
      ...settings,
      prevPage: settings.page,
      page,
      startIndex,
      endIndex,
    });
  };

  const onChangeOrder = (order) => {
    if (settings.order === order) return;
    onChangeSettings({
      ...settings,
      order,
    });
  };

  const onChangeOrderBy = (orderBy) => {
    let toOrderBy = settings.order;
    if (orderBy.column === settings.prevOrderBy.column) {
      toOrderBy = settings.order === 'desc' ? 'asc' : 'desc'
    }
    onChangeSettings({
      ...settings,
      prevOrderBy: settings.orderBy,
      order: toOrderBy,
      orderBy,
    });
  };

  const onChangeLimit = (limit) => {
    if (settings.limit === limit) return;
    onChangeSettings({
      ...settings,
      limit,
    });
  };

  const changeLocale = (locale) => {
    localStorage.setItem('currentLanguage', locale);
    onChangeSettings({
      ...settings,
      locale,
    });
  }

  const setAppTitle = (appTitle) => {
    onChangeSettings({
      ...settings,
      appTitle,
    });
  }

  const setAppBack = (route) => {
    onChangeSettings({
      ...settings,
      appBack: route,
    });
  }

  const setCurrentUserLocation = (location) => {
    onChangeSettings({
      ...settings,
      userCurrentLocation: location,
    });
  }

  const setAppMetaTitle = (title) => {
    onChangeSettings({
      ...settings,
      metaTitle: title,
    });
  }

  const setAppMetaDescription = (description) => {
    onChangeSettings({
      ...settings,
      metaDescription: description,
    });
  }

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        prevSettings,
        settings,
        onChangePage,
        onChangeOrder,
        onChangeOrderBy,
        onChangeSettings,
        onChangeLimit,
        changeLocale,
        setAppTitle,
        setAppBack,
        setCurrentUserLocation,
        setAppMetaTitle,
        setAppMetaDescription,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsProvider, SettingsContext };
