import {
    USERS_UPDATE_USER,
    USERS_UPDATE_USER_SUCCESS,
    USERS_UPDATE_USER_ERROR,
    USER_UPDATE_PROFILE_PIC,
    USER_UPDATE_PROFILE_PIC_SUCCESS,
    USER_UPDATE_PROFILE_PIC_ERROR,
    USER_VIEW_PROPS,
    USER_VIEW_PROPS_SUCCESS,
    USER_VIEW_PROPS_ERROR,
    USER_DELETE,
    USER_DELETE_SUCCESS,
    USER_DELETE_ERROR,
    USER_TOGGLE_SUBSCRIPTION,
    USER_TOGGLE_SUBSCRIPTION_SUCCESS,
    USER_TOGGLE_SUBSCRIPTION_ERROR,
    USER_GET_BY_ID,
    USER_GET_BY_ID_SUCCESS,
    USER_GET_BY_ID_ERROR,
    USER_GET_ME,
    USER_GET_ME_SUCCESS,
    USER_GET_ME_ERROR,
    USER_GET_PROS,
    USER_GET_PROS_SUCCESS,
    USER_GET_PROS_ERROR,
    USER_CLEAR
} from '../actions';

// update user 

export const updateUser = (items) => ({
    type: USERS_UPDATE_USER,
    payload: items
});

export const updateUserSuccess = (items) => ({
    type: USERS_UPDATE_USER_SUCCESS,
    payload: items
});

export const updateUserError = (error) => ({
    type: USERS_UPDATE_USER_ERROR,
    payload: error
});

// update profile pic

export const updateProfilePic = (file) => ({
    type: USER_UPDATE_PROFILE_PIC,
    payload: file
});

export const updateProfilePicSuccess = (items) => ({
    type: USER_UPDATE_PROFILE_PIC_SUCCESS,
    payload: items
});

export const updateProfilePicError = (error) => ({
    type: USER_UPDATE_PROFILE_PIC_ERROR,
    payload: error
});


// view property

export const viewProperty = (item) => ({
  type: USER_VIEW_PROPS,
  payload: item
});

export const viewPropertySuccess = (items) => ({
  type: USER_VIEW_PROPS_SUCCESS,
  payload: items
});

export const viewPropertyError = (error) => ({
  type: USER_VIEW_PROPS_ERROR,
  payload: error
});

// Delete current user 

export const deleteUser = (user) => ({
    type: USER_DELETE,
    payload: user
});

export const deleteUserSuccess = () => ({
    type: USER_DELETE_SUCCESS
});

export const deleteUserError = (error) => ({
    type: USER_DELETE_ERROR,
    payload: error
});

// Toggle user subscription
export const toggleUserSubscription = (params) => ({
    type: USER_TOGGLE_SUBSCRIPTION,
    payload: params
});

export const toggleUserSubscriptionSuccess = (payload) => ({
    type: USER_TOGGLE_SUBSCRIPTION_SUCCESS,
    payload: payload
});

export const toggleUserSubscriptionError = (error) => ({
    type: USER_TOGGLE_SUBSCRIPTION_ERROR,
    payload: error
});

// Get use by id
export const getUserById = (params) => ({
    type: USER_GET_BY_ID,
    payload: params
});

export const getUserByIdSuccess = (user) => ({
    type: USER_GET_BY_ID_SUCCESS,
    payload: user
});

export const getUserByIdError = (error) => ({
    type: USER_GET_BY_ID_ERROR,
    payload: error
});

// clear user
export const clearUser = (params) => ({
    type: USER_CLEAR,
    payload: params
});

// Get me
export const getMe = (params) => ({
    type: USER_GET_ME,
    payload: params
});

export const getMeSuccess = (user) => ({
    type: USER_GET_ME_SUCCESS,
    payload: user
});

export const getMeError = (error) => ({
    type: USER_GET_ME_ERROR,
    payload: error
});

// Get professionals
export const getProfessionals = (params) => ({
    type: USER_GET_PROS,
    payload: params
});

export const getProfessionalsSuccess = (user) => ({
    type: USER_GET_PROS_SUCCESS,
    payload: user
});

export const getProfessionalsError = (error) => ({
    type: USER_GET_PROS_ERROR,
    payload: error
});