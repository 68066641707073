import { createSlice } from '@reduxjs/toolkit';

import { NotificationManager } from "../../components/common/react-notifications";

// utils
import api from "../api";
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  error: null,
  filter: null,
  filters: [],
};

const slice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.loading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    // GET FILTERS
    getFiltersSuccess(state, action) {
      state.loading = false;
      state.filters = action.payload;
    },

    // GET FILTER
    getFilterSuccess(state, action) {
      state.loading = false;
      state.filter = action.payload;
    },

    // CREATE FILTER
    createFilterSuccess(state, action) {
      state.loading = false;
      const newFilter = action.payload.response;
      state.filters.results = [...state.filters.results, newFilter];
    },

    // UPDATE FILTER
    updateFiltertSuccess(state, action) {
      state.loading = false;
      const newFilter = action.payload.response;
      const index = state.filters.results.findIndex(x => x._id == newFilter._id);
      state.filters.results[index] = newFilter;
    },

    // DELETE FILTER
    deleteFilterSuccess(state, action) {
      state.loading = false;
      const deletedId = action.payload;
      const newFilters = state.filters.results.filter(i => i._id !== deletedId);
      state.filters.results = [...newFilters];
    },

    toggleSubscribedToFilter(state, action) {
      const id = action.payload;
      const userFilters = state.filters
      for (let u in userFilters.results) {
        if (userFilters.results[u]._id === id){
          userFilters.results[u].subscribed = !userFilters.results[u].subscribed 
        }
      }
    },

    clearFilters(state, action) {
      state.filter = null;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function createFilter(payload) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
     const { data } = await api.post('/filters', payload, true);
      dispatch(slice.actions.createFilterSuccess(data));
      NotificationManager.success(
        "Filter search was saved",
        "Filter search was successfully saved",
        3000,
        null,
        null,
        ''
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFilters(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.get('/filters', params, true);
      dispatch(slice.actions.getFiltersSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateFilter(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.put('/filters', params.id, params.updates, true);
      dispatch(slice.actions.updateFiltertSuccess(data));
      NotificationManager.success(
        "Filter search was updated",
        "Filter search was successfully updated",
        3000,
        null,
        null,
        ''
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFilter(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.getById('/filters', params, true);
      dispatch(slice.actions.getFilterSuccess(data.response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteFilter(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.delete('/filters', params, true);
      dispatch(slice.actions.deleteFilterSuccess(params));
      NotificationManager.success(
        "Filter search was deleted",
        "Filter search was successfully deleted",
        3000,
        null,
        null,
        ''
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function subscribeToFilter(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.put('/filters/subscribe', params, {}, true);
      dispatch(slice.actions.getFilterSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function toggleSubscribedToFilter(id) {
  return async () => {
    dispatch(slice.actions.toggleSubscribedToFilter(id));
  }
}

// ----------------------------------------------------------------------

export function clearFilters() {
  return async () => {
    dispatch(slice.actions.clearFilters());
  }
}