// import "./assets/css/vendor/bootstrap.min.css";
import "react-circular-progressbar/dist/styles.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import 'react-image-lightbox/style.css';
import "./assets/css/sass/_style.scss";

let render = () => {

  const hideChat = () => {
    window.tidioChatApi.display(false);
  }

  if (window.tidioChatApi) {
    window.tidioChatApi.on("ready", hideChat);
    window.tidioChatApi.on("close", hideChat);
  } else {
    document.addEventListener("tidioChat-ready", hideChat);
  }
  
  // import('./assets/css/sass/themes/gogo.' + color + '.scss').then(x => {
     require('./AppRenderer');
  //});
};

render();