import { fas } from '@fortawesome/pro-solid-svg-icons';
import { createSlice } from '@reduxjs/toolkit';

// utils
import api from "../api";
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
	relatedProperties: [],
	properties: null,
  prevProperties: null,
	mapList: null,
	cluster: null,
  prevCluster: null,
	point: null,
	nearby: null,
	likedProperties: [],
	viewedProperties: [],
	property: null,
	error: null,
	searchKeyword: '',
	orderColumn: null,
	loading: false,
	loadingMap: false,
	stats: null,
  isCluster: false,
  sliderProperty: null,
  isSearching: false,
};

const slice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.loading = true;
    },

     // START LOADING
    startMapLoading(state) {
      state.loadingMap = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    // GET BUDGETS
    getPropertiesSuccess(state, action) {
      state.loading = false;
      console.log(action.payload, 'action.payload');
      state.properties = action.payload;
    },

    // GET PROPERTY
    getPropertySuccess(state, action) {
      state.loading = false;
      state.property = action.payload;
    },

    // UPDATE PROPERTY
    updatePropertySuccess(state, action) {
      state.loading = false;
      state.property = action.payload;
    },

    // GET PROPERTY STATS
    getPropertyStatsSuccess(state, action) {
      state.loading = false;
      state.stats = action.payload;
    },

    // GET LIKED PROPERTIES
    getLikedPropertiesSuccess(state, action) {
      state.loading = false;
      state.likedProperties = action.payload;
    },

    // GET VIEWED PROPERTIES
    getViewedPropertiesSuccess(state, action) {
      state.loading = false;
      state.viewedProperties = action.payload;
    },

    // GET POINT ON MAP PROPERTIES
    getMapPointSuccess(state, action) {
      state.loading = false;
      state.point = action.payload;
    },

    // GET RELATED PROPERTIES
    getRelatedPropertiesSuccess(state, action) {
      state.loading = false;
      state.relatedProperties = action.payload;
    },

    // GET PROPERTIES NEARBY
    getMapNearbyPropertiesSuccess(state, action) {
      state.loading = false;
      state.nearby = action.payload;
    },

    // GET PROPERTIES OF A CLUSTER
    getMapClusterSuccess(state, action) {
      state.loading = false;
      state.prevCluster = state.cluster;
      state.cluster = action.payload;
    },

    // LIST PROPERTIES
    getMapListSuccess(state, action) {
      state.loading = false;
      state.loadingMap = false;
      state.mapList = action.payload;
      state.properties = action.payload.results;
    },

    // SET SELECTED PROPERTY
    setSliderProperty(state, action) {
      const { property, isCluster } = action.payload;
      state.sliderProperty = property;
      state.isCluster = isCluster;
    },

    setSeachingListings(state, action) {
      state.isSearching = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getProperties(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.get('/properties', params);
      dispatch(slice.actions.getPropertiesSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMapList(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startMapLoading());
    try {
      const { data } = await api.get('/properties/map', params);
      dispatch(slice.actions.getMapListSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCluster(payload) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.post('/properties/cluster', payload);
      dispatch(slice.actions.getMapClusterSuccess({
        results: data.response,
      }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getRelatedProperties(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.get('/properties', params);
      dispatch(slice.actions.getRelatedPropertiesSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNearby(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.get('/properties/near', params);
      dispatch(slice.actions.getMapNearbyPropertiesSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function updateProperty(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.put('/properties', params.id, params.payload, true);
      dispatch(slice.actions.updatePropertySuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProperty(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.getById('/properties', params, true);
      const { response } = data;
      const images = JSON.parse(response.photos);
      const photos = images.map((p)=> {
        if (p.headerInfo.location) {
         return {
            img: p.headerInfo.location,
            id: p.headerInfo.contentId
          };
        } 
        return null;
      })
  
      response.photos = JSON.stringify(photos.filter(i => i));
      dispatch(slice.actions.getPropertySuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPoint(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.get(`/properties/${id}`);
      dispatch(slice.actions.getMapPointSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getViewedProperties(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.get('/buyers/viewed-properties', {}, true);
      dispatch(slice.actions.getViewedPropertiesSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPropertyStats(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.get('/properties/stats', {}, true);
      dispatch(slice.actions.getPropertyStatsSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function selectSliderProperty(property, isCluster = false) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setSliderProperty({
        property,
        isCluster,
      }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function onSearchListings(isSearching) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setSeachingListings(isSearching));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}